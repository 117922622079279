<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.template_name"
              item-name="template_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.template_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.template_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
              v-show="!workingDataExpand"
            />
            <ValidationSelect
              v-model="detail.encode"
              item-name="encode"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.encode"
              :options="comboData.encode_list"
              :disabled="isReadOnly"
              @change="encodeChanged"
              v-show="!workingDataExpand"
            />
            <ValidationSelect
              v-model="detail.quotation_mark"
              item-name="quotation_mark"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.quotation_mark"
              :options="comboData.quotation_mark_list"
              :disabled="isReadOnly"
              @change="quotationMarkChanged"
              v-show="!workingDataExpand"
            />
            <ValidationSelect
              v-model="detail.field_separator"
              item-name="field_separator"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.field_separator"
              :options="comboData.field_separator_list"
              :disabled="isReadOnly"
              @change="fieldSeparatorChanged"
              v-show="!workingDataExpand"
            />
            <ValidationSelect
              v-model="detail.is_header_disp"
              item-name="is_header_disp"
              :placeholder="$t('label.select_description')"
              classes="length"
              rules="required"
              :item-label="labels.is_header_disp"
              :options="comboData.is_header_disp_list"
              :disabled="isReadOnly"
              @change="isHeaderDispChanged"
              v-show="!workingDataExpand"
            />
            <div style="color: #C84513; margin-bottom: 5px;" v-for="error in workingDataExportListErrors" v-bind:key="error">
              <small>{{error}}</small>
            </div>
            <ValidationWorkingDataExport
              v-model="workingDataExportList"
              :item-label1="labels.working_item"
              :item-label2="labels.working_item_unit"
              :item-label3="labels.is_quotation"
              :item-label4="labels.is_zero_suppress"
              :disabled="isReadOnly"
              :options="comboData.working_item_unit_list"
              :expand="workingDataExpand"
              @expand="expand"
              rules="isWorkingItemRequired"
              :quotation-mark="quotationMark"
              ref="applicationControl"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import CsvTemplateFormatDetailMixin from '@/components/Dashboard/Views/Generated/CsvTemplateFormatDetailMixin'

  export default {
    name: 'CsvTemplateFormatDetail',
    data () {
      return {
        timeUnitItems: ['21', '22', '25', '26', '28', '29', '30', '32', '33', '34', '36', '38', '40', '43', '44', '45', '46', '47', '48', '52', '53', '61', '62', '63', '64', '65', '66', '69'],
        dayUnitItems: ['12', '13', '14', '15', '16', '17', '18', '19', '20', '23', '24', '27', '31', '35', '37', '39', '41', '42', '49', '50', '51', '54', '55', '56', '57', '60', '67'],
        workingDataExportList: [],
        workingDataExportListErrors: [],
        quotationMark: '',
        workingDataExpand: false
      }
    },
    methods: {
      afterInit () {
        this.labels = this.params.labels
        this.labels.vuePlaceholder_template_name = this.labels.vuePlaceholder_csv_template_name
        this.setVueLanguage(this.labels)
        this.params.comboData.is_header_disp_list = this.params.comboData.availability_list
        this.comboData = this.params.comboData
        this.workingDataExportList = this.comboData.working_item_list.map((row) => ({
          working_item: String(row.value),
          working_item_name: row.label ? row.label.split(',')[0] : '',
          working_item_unit: row.working_item_unit ? String(this.comboData.working_item_unit_list.filter((unit) => row.working_item_unit === unit.value)[0].format_serial_number) : null,
          working_item_unit_list: this.comboData.working_item_unit_list.filter((unit) => row.working_item_unit === unit.value),
          is_quotation: row.working_item_unit ? '2' : '1',
          is_quotation_list: this.params.comboData.availability_list,
          is_zero_suppress: '2',
          is_zero_suppress_list: this.params.comboData.availability_list,
          enabled: false,
          sort_number: 999
        }))
        if (this.params.row.itemList) {
          this.detail.template_number = this.params.row.template_number
          this.detail.template_name = this.params.row.template_name
          this.detail.encode = String(this.params.row.encode)
          this.detail.field_separator = String(this.params.row.field_separator)
          this.quotationMark = this.detail.quotation_mark = String(this.params.row.quotation_mark)
          this.detail.is_header_disp = this.params.row.is_header_disp ? '1' : '2'
          this.workingDataExportList = this.workingDataExportList.map((row2) => {
            const row = this.params.row.itemList.find((row3) => String(row3.working_item) === row2.working_item)
            return row ? {
              working_item: String(row.working_item),
              working_item_name: this.comboData.working_item_list.find((row2) => String(row2.value) === row.working_item).label ? this.comboData.working_item_list.find((row2) => String(row2.value) === row.working_item).label.split(',')[0] : '',
              working_item_unit: row.format_serial_number ? String(row.format_serial_number) : null,
              working_item_unit_list: this.comboData.working_item_unit_list.filter((unit) => this.comboData.working_item_list.find((item) => String(item.value) === row.working_item).working_item_unit === unit.value),
              is_quotation: row.is_quotation ? '1' : '2',
              is_quotation_list: this.params.comboData.availability_list,
              is_zero_suppress: row.is_zero_suppress ? '1' : '2',
              is_zero_suppress_list: this.params.comboData.availability_list,
              enabled: row.enabled === 1,
              sort_number: row.sort_number
            } : row2
          })
          this.workingDataExportList.sort((a, b) => a.sort_number - b.sort_number)
        }
        this.initialized = true
      },
      quotationMarkChanged () {
        this.quotationMark = this.detail.quotation_mark
      },
      convertDetail (detail) {
        if (this.mode === this.modes.add) {
          delete detail.template_number
        } else if (this.mode === this.modes.edit) {
          delete detail.update_count
        } else if (this.mode === this.modes.delete) {
          return {template_number: this.detail.template_number}
        }
        detail.encode = parseInt(detail.encode, 10)
        detail.field_separator = parseInt(detail.field_separator, 10)
        detail.quotation_mark = parseInt(detail.quotation_mark, 10)
        detail.is_header_disp = detail.is_header_disp === '1'
        detail.item_list = this.workingDataExportList.map((row, index) => ({
          working_item: row.working_item,
          working_item_unit: row.working_item_unit ? row.working_item_unit_list.filter((unit) => row.working_item_unit === unit.format_serial_number)[0].value : null,
          format_serial_number: row.working_item_unit ? parseInt(row.working_item_unit, 10) : null,
          is_quotation: row.is_quotation === '1',
          sort_number: index + 1,
          is_zero_suppress: row.is_zero_suppress === '1',
          enabled: row.enabled ? 1 : 2
        }))
        delete detail.working_data_export_list
        return detail
      },
      expand () {
        this.workingDataExpand = !this.workingDataExpand
      }
    },
    mixins: [CsvTemplateFormatDetailMixin]
  }
</script>
<style scoped>
</style>
