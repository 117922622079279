import DashboardLayout from '../components/Dashboard/Layout/DashboardLayout.vue'
// GeneralViews
import NotFound from '../components/GeneralViews/NotFoundPage.vue'
import InternalServerError from '../components/GeneralViews/InternalServerErrorPage.vue'
// ダッシュボード
import DashboardFrame from 'src/components/Dashboard/Views/Dashboard/DashboardFrame.vue'
// 勤怠
import AttendanceRecordList from 'src/components/Dashboard/Views/Working/AttendanceRecordList.vue'
import AttendanceRecordDetail from 'src/components/Dashboard/Views/Working/AttendanceRecordDetail.vue'
import TimeCard from 'src/components/Dashboard/Views/Working/TimeCard.vue'
import PaidHolidays from 'src/components/Dashboard/Views/Working/PaidHolidaysSearch.vue'
import ApplicationList from 'src/components/Dashboard/Views/Working/ApplicationListSearch.vue'
import ApprovalList from 'src/components/Dashboard/Views/Working/ApprovalListSearch.vue'
import ApplicationStatus from 'src/components/Dashboard/Views/Working/ApplicationStatusSearch.vue'
import ApprovalStatus from 'src/components/Dashboard/Views/Working/ApprovalStatusSearch.vue'
import Workflow from 'src/components/Dashboard/Views/Working/Workflow/Workflow.vue'
import LoginUserPaidHolidays from 'src/components/Dashboard/Views/Working/LoginUserPaidHolidays.vue'
import WfImprintCorrectionSearch from 'src/components/Dashboard/Views/Working/WfImprintCorrectionSearch.vue'

// 給与/賞与
import CreatePaySlipSearch from 'src/components/Dashboard/Views/Salary/CreatePaySlipSearch.vue'
import CreatePaySlipDetail from 'src/components/Dashboard/Views/Salary/CreatePaySlipDetail.vue'
import CreateBonusPaySlipSearch from 'src/components/Dashboard/Views/Salary/CreateBonusPaySlipSearch.vue'
import CreateBonusPaySlipSummary from 'src/components/Dashboard/Views/Salary/CreateBonusPaySlipSummary.vue'
import CreateBonusPaySlipDetail from 'src/components/Dashboard/Views/Salary/CreateBonusPaySlipDetail.vue'
import BonusPaySlip from 'src/components/Dashboard/Views/Salary/BonusPaySlipSearch.vue'
import ReferencePaySlipSearch from 'src/components/Dashboard/Views/Salary/ReferencePaySlipSearch.vue'
import ReferencePaySlipDetail from 'src/components/Dashboard/Views/Salary/ReferencePaySlipDetail.vue'
// マスタ設定
import CompanyView from 'src/components/Dashboard/Views/Master/CompanyView.vue'
import EmployeeSearch from 'src/components/Dashboard/Views/Master/EmployeeSearch.vue'
import SalaryItem from 'src/components/Dashboard/Views/Master/SalaryItemSearch.vue'
import Rate from 'src/components/Dashboard/Views/Master/RateView.vue'
import PaySlipLayout from 'src/components/Dashboard/Views/Master/PaySlipLayoutSearch.vue'
import BonusLayout from 'src/components/Dashboard/Views/Master/BonusLayoutSearch.vue'
import TaskSearch from 'src/components/Dashboard/Views/Master/TaskSearch.vue'
import ParlanceSearch from 'src/components/Dashboard/Views/Master/ParlanceSearch.vue'
import ForgotImprint from 'src/components/Dashboard/Views/Master/ForgotImprint.vue'
import EmployeeRoundingSearch from 'src/components/Dashboard/Views/Master/EmployeeRoundingSearch.vue'
// マスタ設定[プロジェクト]
import CustomerSearch from 'src/components/Dashboard/Views/MasterProject/CustomerSearch.vue'
import CustomerDepartment from 'src/components/Dashboard/Views/MasterProject/CustomerDepartmentSearch.vue'
import SupplierSearch from 'src/components/Dashboard/Views/MasterProject/SupplierSearch.vue'
import SupplierDepartment from 'src/components/Dashboard/Views/MasterProject/SupplierDepartmentSearch.vue'
import ProductSearch from 'src/components/Dashboard/Views/MasterProject/ProductSearch.vue'
// 自動生成
import GeneratedApplicationClassificationSearch from 'src/components/Dashboard/Views/Generated/ApplicationClassificationSearch.vue'
import GeneratedWorkScheduleAllocationSearch from 'src/components/Dashboard/Views/Generated/WorkScheduleAllocationSearch.vue'
import GeneratedOfficeSearch from 'src/components/Dashboard/Views/Generated/OfficeSearch.vue'
import GeneratedBusinessSearch from 'src/components/Dashboard/Views/Generated/BusinessSearch.vue'
import GeneratedWorkScheduleSearch from 'src/components/Dashboard/Views/Generated/WorkScheduleSearch.vue'
import GeneratedPaidHolidayManagement from 'src/components/Dashboard/Views/Generated/PaidHolidayManagementSheet.vue'
import GeneratedTeamSearch from 'src/components/Dashboard/Views/Generated/TeamSearch.vue'
import GeneratedPaidHolidayPayment from 'src/components/Dashboard/Views/Generated/PaidHolidayPaymentSheet.vue'
import GeneratedLunchSupplierSearch from 'src/components/Dashboard/Views/Generated/LunchSupplierSearch.vue'
import GeneratedLunchSearch from 'src/components/Dashboard/Views/Generated/LunchSearch.vue'
import GeneratedRoleSearch from 'src/components/Dashboard/Views/Generated/RoleSearch.vue'
import GeneratedRoleAllocationSearch from 'src/components/Dashboard/Views/Generated/RoleAllocationSearch.vue'
import GeneratedEmployeeRoleAllocationSearch from 'src/components/Dashboard/Views/Generated/EmployeeRoleAllocationSearch.vue'
import GeneratedApprovalRouteSearch from 'src/components/Dashboard/Views/Generated/ApprovalRouteSearch.vue'
import GeneratedApplicationFormSearch from 'src/components/Dashboard/Views/Generated/ApplicationFormSearch.vue'
import GeneratedAuthority from 'src/components/Dashboard/Views/Generated/AuthoritySheet.vue'
import GeneratedPlaceSearch from 'src/components/Dashboard/Views/Generated/PlaceSearch.vue'
import GeneratedClosingSearch from 'src/components/Dashboard/Views/Generated/ClosingSearch.vue'
import GeneratedEmployeeClassificationSearch from 'src/components/Dashboard/Views/Generated/EmployeeClassificationSearch.vue'
import GeneratedLunchOrder from 'src/components/Dashboard/Views/Generated/LunchOrderSheet.vue'
import GeneratedGroupSearch from 'src/components/Dashboard/Views/Generated/GroupSearch.vue'
import GeneratedGroupDivisionSearch from 'src/components/Dashboard/Views/Generated/GroupDivisionSearch.vue'
import GeneratedGroupManagementSearch from 'src/components/Dashboard/Views/Generated/GroupManagementSearch.vue'
import GeneratedSalaryIncreaseSimulationSearch from 'src/components/Dashboard/Views/Generated/SalaryIncreaseSimulationSearch.vue'
import GeneratedSearchScreen from 'src/components/Dashboard/Views/Generated/SearchScreenSheet.vue'
import GeneratedEmployeeAge from 'src/components/Dashboard/Views/Generated/EmployeeAgeSheet.vue'
import GeneratedResidentTaxSearch from 'src/components/Dashboard/Views/Generated/ResidentTaxSearch.vue'
import GeneratedWageLedgerSearch from 'src/components/Dashboard/Views/Generated/WageLedgerSearch.vue'
import GeneratedGroundSheet from 'src/components/Dashboard/Views/Generated/GroundSheet.vue'
import GeneratedWorkersListSearch from 'src/components/Dashboard/Views/Generated/WorkersListSearch.vue'
import GeneratedSalaryClosingSearch from 'src/components/Dashboard/Views/Generated/SalaryClosingSearch.vue'
import GeneratedJobTitleSearch from 'src/components/Dashboard/Views/Generated/JobTitleSearch.vue'
import GeneratedUnionSearch from 'src/components/Dashboard/Views/Generated/UnionSearch.vue'
import GeneratedPaidAnnualAccountEmployeeSearch from 'src/components/Dashboard/Views/Generated/PaidAnnualAccountEmployeeSearch.vue'
import GeneratedOperationNoticeSearch from 'src/components/Dashboard/Views/Generated/OperationNoticeSearch.vue'
import GeneratedLanguageSearch from 'src/components/Dashboard/Views/Generated/LanguageSearch.vue'
import GeneratedScreenSearch from 'src/components/Dashboard/Views/Generated/ScreenSearch.vue'
import GeneratedTransformationWeeklySearch from 'src/components/Dashboard/Views/Generated/TransformationWeeklySearch.vue'
import GeneratedTransformationMonthlySearch from 'src/components/Dashboard/Views/Generated/TransformationMonthlySearch.vue'
import GeneratedTransformationYearlySearch from 'src/components/Dashboard/Views/Generated/TransformationYearlySearch.vue'
import GeneratedLunchOrderResultListSearch from 'src/components/Dashboard/Views/Generated/LunchOrderResultListSearch.vue'
import GeneratedMessageSearch from 'src/components/Dashboard/Views/Generated/MessageSearch.vue'
import GeneratedRecruitmentCategorySearch from 'src/components/Dashboard/Views/Generated/RecruitmentCategorySearch.vue'
import GeneratedWorkStatusSearch from 'src/components/Dashboard/Views/Generated/WorkStatusSearch.vue'
import GeneratedPaidPaymentManagementDetailSearch from 'src/components/Dashboard/Views/Generated/PaidPaymentManagementDetailSearch.vue'
import GeneratedPaySlipsListSearch from 'src/components/Dashboard/Views/Generated/PaySlipsListSearch.vue'
import GeneratedBonusDetailsSearch from 'src/components/Dashboard/Views/Generated/BonusDetailsSearch.vue'
import GeneratedReportSearch from 'src/components/Dashboard/Views/Generated/ReportSearch.vue'
import GeneratedWorkTypeSearch from 'src/components/Dashboard/Views/Generated/WorkTypeSearch.vue'
import GeneratedShiftPatternSearch from 'src/components/Dashboard/Views/Generated/ShiftPatternSearch.vue'
import GeneratedCustomerCompanySearch from 'src/components/Dashboard/Views/Generated/CustomerCompanySearch.vue'
import GeneratedCsvTemplateFormatSearch from 'src/components/Dashboard/Views/Generated/CsvTemplateFormatSearch.vue'
import GeneratedResidentTaxSettingSearch from 'src/components/Dashboard/Views/Generated/ResidentTaxSettingSearch.vue'
import GeneratedPublicHolidaySearch from 'src/components/Dashboard/Views/Generated/PublicHolidaySearch.vue'
import GeneratedCsvOutputItemSearch from 'src/components/Dashboard/Views/Generated/CsvOutputItemSearch.vue'
import GeneratedCsvTemplateSearch from 'src/components/Dashboard/Views/Generated/CsvTemplateSearch.vue'
// 月次処理
import ClosingAttendanceRecord from 'src/components/Dashboard/Views/Monthly/ClosingAttendanceRecord.vue'
import ChangeSocialInsuranceSearch from 'src/components/Dashboard/Views/Monthly/ChangeSocialInsuranceSearch.vue'
import ShiftSettingSearch from 'src/components/Dashboard/Views/Monthly/ShiftSettingSearch.vue'
import DesiredShiftConfirmSearch from 'src/components/Dashboard/Views/Monthly/DesiredShiftConfirmSearch.vue'
import ShiftDecisionSearch from 'src/components/Dashboard/Views/Monthly/ShiftDecisionSearch.vue'
// 年次処理
import AnnualCalendarSearch from 'src/components/Dashboard/Views/Annual/AnnualCalendarSearch.vue'
import LegalRuleSearch from 'src/components/Dashboard/Views/Annual/LegalRuleSearch.vue'
import CalculationBasisSearch from 'src/components/Dashboard/Views/Annual/CalculationBasisSearch.vue'
// 台帳
import ReportList from 'src/components/Dashboard/Views/Ledger/ReportList.vue'
import UploadList from 'src/components/Dashboard/Views/Ledger/UploadList.vue'
// 分析
import StagnationSearch from 'src/components/Dashboard/Views/Analysis/StagnationSearch.vue'
import LegalRuleSurveillanceSearch from 'src/components/Dashboard/Views/Analysis/LegalRuleSurveillanceSearch.vue'
import LegalRuleSurveillanceDayData from 'src/components/Dashboard/Views/Analysis/LegalRuleSurveillanceDayData.vue'
// プロジェクト管理
import QuotationSearch from 'src/components/Dashboard/Views/ProjectManagement/QuotationSearch.vue'
// 運営会社
import WithholdingTaxAmount from 'src/components/Dashboard/Views/Operation/WithholdingTaxAmountView.vue'
import NoticeConfirmation from 'src/components/Dashboard/Views/Operation/NoticeConfirmation.vue'
import ApplicationFormManagement from '@/components/Dashboard/Views/Operation/ApplicationFormManagement'
import ApplicationClassificationManagement from '@/components/Dashboard/Views/Operation/ApplicationClassificationManagement'

// 管理者
import AdminLogin from 'src/components/Dashboard/Views/Admin/Login.vue'
import AdminMenu from 'src/components/Dashboard/Views/Admin/Menu.vue'
import MailAddressChange from 'src/components/Dashboard/Views/Admin/MailAddressChange.vue'
import MailAddressComplete from 'src/components/Dashboard/Views/Admin/MailAddressComplete.vue'
import UserPasswordChange from 'src/components/Dashboard/Views/Admin/PasswordChange.vue'
import UserDashboardLayout from '../components/Dashboard/Layout/UserDashboardLayout.vue'
import Payment from '../components/Dashboard/Views/Admin/Payment.vue'
import UpdateCreditCard from 'src/components/Dashboard/Views/Admin/UpdateCreditCard.vue'
import Invoice from '../components/Dashboard/Views/Admin/Invoice.vue'
import DeleteCompany from '../components/Dashboard/Views/Admin/DeleteCompany.vue'

// QRコード打刻
import QrcodeLogin from 'src/components/Dashboard/Views/Qrcode/Login.vue'
import QrcodeStamp from 'src/components/Dashboard/Views/Qrcode/Stamp.vue'

// 移行
import Start from 'src/components/Dashboard/Views/Migration/Start.vue'
import Agreement from 'src/components/Dashboard/Views/Migration/Agreement.vue'
import WizardForm from 'src/components/Dashboard/Views/Migration/WizardForm.vue'
import Waiting from 'src/components/Dashboard/Views/Migration/Waiting.vue'
import PaymentComplete from 'src/components/Dashboard/Views/Migration/PaymentComplete.vue'

// My Collier
import MyCollierPrivacyPolicy from 'src/components/Dashboard/Views/MyCollier/PrivacyPolicy.vue'
import MyCollierTermsOfUse from 'src/components/Dashboard/Views/MyCollier/TermsOfUse.vue'

// Login
import Login from 'src/components/Dashboard/Views/Login/Login.vue'
import Register from 'src/components/Dashboard/Views/Login/Register.vue'
import About from 'src/components/Dashboard/Views/Login/About.vue'
import NewCompany from 'src/components/Dashboard/Views/Login/NewCompany.vue'
import PrivacyPolicy from 'src/components/Dashboard/Views/Login/PrivacyPolicy.vue'
import TermsOfUse from 'src/components/Dashboard/Views/Login/TermsOfUse.vue'
import ForgetPassword from 'src/components/Dashboard/Views/Login/ForgetPassword.vue'
import ForgetPasswordAdmin from 'src/components/Dashboard/Views/Login/ForgetPasswordAdmin.vue'
import ResetPassword from 'src/components/Dashboard/Views/Login/ResetPassword.vue'
import ResetPasswordAdmin from 'src/components/Dashboard/Views/Login/ResetPasswordAdmin.vue'
import NotationOnLaw from 'src/components/Dashboard/Views/Login/NotationOnLaw.vue'
import ChatSupport from 'src/components/Dashboard/Views/Login/ChatSupport.vue'
import GoogleUserDataPolicy from 'src/components/Dashboard/Views/Login/GoogleUserDataPolicy.vue'

// 会社登録
import CompanyRegist from 'src/components/Dashboard/Views/CompanyRegist/CompanyRegist.vue'
import CompanyWizardForm from 'src/components/Dashboard/Views/CompanyRegist/CompanyWizardForm.vue'

// Maps pages
import VectorMaps from 'src/components/Dashboard/Views/Maps/VectorMapsPage.vue'

// 設定
import ChangePassword from 'src/components/Dashboard/Views/Setting/ChangePassword.vue'
import NoticeSearch from 'src/components/Dashboard/Views/Setting/NoticeSearch.vue'
// 代理承認者設定
import ProxyApprovalSetting from 'src/components/Dashboard/Views/Setting/ProxyApprovalSetting.vue'
import Boss from 'src/components/Dashboard/Views/Setting/Boss.vue'
import EmployeeAlertManagement from 'src/components/Dashboard/Views/Setting/EmployeeAlertManagement.vue'
import EmployeeArtemisAlertManagement from 'src/components/Dashboard/Views/Setting/EmployeeArtemisAlertManagement.vue'

// 従業員
import EmployeeInfo from 'src/components/UIComponents/SidebarPlugin/EmployeeInfo.vue'
import DeputyApproverInfo from 'src/components/UIComponents/SidebarPlugin/DeputyApproverInfo.vue'

// 労務管理
import Vaccination from 'src/components/Dashboard/Views/Labor/VaccinationSearch.vue'

// スコア
import Score from 'src/components/Dashboard/Views/Analysis/Score.vue'

let mapsMenu = {
  path: '/maps',
  component: DashboardLayout,
  redirect: '/maps/vector-map',
  children: [
    {
      path: 'vector-map',
      name: 'Vector Map',
      component: VectorMaps
    }
  ]
}

let loginPage = {
  path: '/login',
  name: 'Login',
  component: Login
}

let registerPage = {
  path: '/register',
  name: 'Register',
  component: Register
}

const routes = [
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/login'
  },
  mapsMenu,
  loginPage,
  registerPage,
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/new-company',
    name: 'newCompany',
    component: NewCompany
  },
  {
    path: '/forget-password',
    name: 'forgetPassword',
    component: ForgetPassword
  },
  {
    path: '/forget-password-admin',
    name: 'forgetPasswordAdmin',
    component: ForgetPasswordAdmin
  },
  {
    path: '/reset-password',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/private-reset-password',
    name: 'resetPasswordAdmin',
    component: ResetPasswordAdmin
  },
  {
    path: '/privacy-policy',
    name: 'privacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-of-use',
    name: 'termsOfUse',
    component: TermsOfUse
  },
  {
    path: '/notation-on-law',
    name: 'notationOnLaw',
    component: NotationOnLaw
  },
  {
    path: '/chat-support',
    name: 'chatSupport',
    component: ChatSupport
  },
  {
    path: '/my-collier/privacy-policy',
    name: 'myCollierPrivacyPolicy',
    component: MyCollierPrivacyPolicy
  },
  {
    path: '/my-collier/terms-of-use',
    name: 'myCollierTermsOfUse',
    component: MyCollierTermsOfUse
  },
  {
    path: '/google-user-data-policy',
    name: 'googleUserDataPolicy',
    component: GoogleUserDataPolicy
  },
  {
    path: '/company-regist',
    name: 'companyRegist',
    component: CompanyRegist
  },
  {
    path: '/company-wizard',
    name: 'companyWizard',
    component: CompanyWizardForm
  },
  {
    path: '/DB',
    component: DashboardLayout,
    redirect: '/DB/menuDBScreen001',
    children: [
      {
        path: 'menuDBScreen001',
        name: 'dashboardFrame',
        component: DashboardFrame
      }
    ]
  },
  {
    path: '/01',
    component: DashboardLayout,
    redirect: '/01/menu01Screen001',
    children: [
      {
        path: 'menu01Screen001',
        name: 'タイムカード',
        component: TimeCard
      }
    ]
  },
  {
    path: '/01/menu01Screen002',
    component: DashboardLayout,
    redirect: '/01/menu01Screen002/list',
    children: [
      {
        path: 'list',
        name: 'attendanceRecordList',
        component: AttendanceRecordList
      },
      {
        path: 'detail',
        name: 'attendanceRecordDetail',
        component: AttendanceRecordDetail
      }
    ]
  },
  {
    path: '/01/menu01Screen003',
    component: DashboardLayout,
    redirect: '/01/menu01Screen003/list',
    children: [
      {
        path: 'list',
        name: 'attendanceRecordListPersonal',
        component: AttendanceRecordList
      },
      {
        path: 'personal',
        name: 'attendanceRecordDetailPersonal',
        component: AttendanceRecordDetail
      }
    ]
  },
  {
    path: '/01/menu01Screen004',
    component: DashboardLayout,
    redirect: '/01/menu01Screen004/search',
    children: [
      {
        path: 'search',
        name: 'PaidHolidaysSearch',
        component: PaidHolidays
      }
    ]
  },
  {
    path: '/01/menu01Screen005',
    component: DashboardLayout,
    redirect: '/01/menu01Screen005/search',
    children: [
      {
        path: 'search',
        name: 'ApplicationListSearch',
        component: ApplicationList
      }
    ]
  },
  {
    path: '/01/menu01Screen006',
    component: DashboardLayout,
    redirect: '/01/menu01Screen006/search',
    children: [
      {
        path: 'search',
        name: 'ApprovalListSearch',
        component: ApprovalList
      }
    ]
  },
  {
    path: '/01/menu01Screen009',
    component: DashboardLayout,
    redirect: '/01/menu01Screen009/search',
    children: [
      {
        path: 'search',
        name: 'ApplicationStatusSearch',
        component: ApplicationStatus
      }
    ]
  },
  {
    path: '/01/menu01Screen010',
    component: DashboardLayout,
    redirect: '/01/menu01Screen010/search',
    children: [
      {
        path: 'search',
        name: 'ApprovalStatusSearch',
        component: ApprovalStatus
      }
    ]
  },
  {
    path: '/01/menu09Screen011',
    component: DashboardLayout,
    redirect: '/01/menu09Screen011/search',
    children: [
      {
        path: 'search',
        name: 'LoginUserPaidHolidaysSearch',
        component: LoginUserPaidHolidays
      }
    ]
  },
  {
    path: '/01/menu01Screen008',
    component: DashboardLayout,
    redirect: '/01/menu01Screen008/search',
    children: [
      {
        path: 'search',
        name: 'generatedWorkScheduleAllocationSearch',
        component: GeneratedWorkScheduleAllocationSearch
      }
    ]
  },
  {
    path: '/01/menu01Screen014',
    component: DashboardLayout,
    redirect: '/01/menu01Screen014/search',
    children: [
      {
        path: 'search',
        name: 'generatedWorkStatusSearch',
        component: GeneratedWorkStatusSearch
      }
    ]
  },
  {
    path: '/01/menu01Screen015',
    component: DashboardLayout,
    redirect: '/01/menu01Screen015/search',
    children: [
      {
        path: 'search',
        name: 'generatedPaidPaymentManagementDetailSearch',
        component: GeneratedPaidPaymentManagementDetailSearch
      }
    ]
  },
  {
    path: '/01/menu01Screen016',
    component: DashboardLayout,
    redirect: '/01/menu01Screen016/search',
    children: [
      {
        path: 'search',
        name: 'wfImprintCorrectionSearch',
        component: WfImprintCorrectionSearch
      }
    ]
  },
  {
    path: '/02/menu02Screen001',
    component: DashboardLayout,
    redirect: '/02/menu02Screen001/search',
    children: [
      {
        path: 'search',
        name: 'createPaySlipSearch',
        component: CreatePaySlipSearch
      },
      {
        path: 'detail',
        name: 'createPaySlipDetail',
        component: CreatePaySlipDetail
      }
    ]
  },
  {
    path: '/02/menu02Screen002',
    component: DashboardLayout,
    redirect: '/02/menu02Screen002/search',
    children: [
      {
        path: 'search',
        name: 'createBonusPaySlipSearch',
        component: CreateBonusPaySlipSearch
      },
      {
        path: 'summary',
        name: 'createBonusPaySlipSummary',
        component: CreateBonusPaySlipSummary
      },
      {
        path: 'detail',
        name: 'createBonusPaySlipDetail',
        component: CreateBonusPaySlipDetail
      }
    ]
  },
  {
    path: '/02/menu02Screen003',
    component: DashboardLayout,
    redirect: '/02/menu02Screen003/search',
    children: [
      {
        path: 'search',
        name: 'referencePaySlipSearch',
        component: ReferencePaySlipSearch
      },
      {
        path: 'detail',
        name: 'referencePaySlipDetail',
        component: ReferencePaySlipDetail
      }
    ]
  },
  {
    path: '/02/menu02Screen004',
    component: DashboardLayout,
    redirect: '/02/menu02Screen004/search',
    children: [
      {
        path: 'search',
        name: 'BonusPaySlip',
        component: BonusPaySlip
      }
    ]
  },
  {
    path: '/03/menu03Screen047',
    component: DashboardLayout,
    redirect: '/03/menu03Screen047/search',
    children: [
      {
        path: 'search',
        name: 'generatedResidentTaxSettingSearch',
        component: GeneratedResidentTaxSettingSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen048',
    component: DashboardLayout,
    redirect: '/03/menu03Screen048/search',
    children: [
      {
        path: 'search',
        name: 'generatedCsvOutputItemSearch',
        component: GeneratedCsvOutputItemSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen049',
    component: DashboardLayout,
    redirect: '/03/menu03Screen049/search',
    children: [
      {
        path: 'search',
        name: 'generatedCsvTemplateSearch',
        component: GeneratedCsvTemplateSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen002',
    component: DashboardLayout,
    redirect: '/03/menu03Screen002/search',
    children: [
      {
        path: 'search',
        name: 'generatedOfficeSearch',
        component: GeneratedOfficeSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen003',
    component: DashboardLayout,
    redirect: '/03/menu03Screen003/search',
    children: [
      {
        path: 'search',
        name: 'generatedBusinessSearch',
        component: GeneratedBusinessSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen004',
    component: DashboardLayout,
    redirect: '/03/menu03Screen004/search',
    children: [
      {
        path: 'search',
        name: 'generatedWorkScheduleSearch',
        component: GeneratedWorkScheduleSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen014',
    component: DashboardLayout,
    redirect: '/03/menu03Screen014/search',
    children: [
      {
        path: 'search',
        name: 'generatedLunchSupplierSearch',
        component: GeneratedLunchSupplierSearch
      },
      {
        path: 'lunch',
        name: 'generatedLunchSearch',
        component: GeneratedLunchSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen015',
    component: DashboardLayout,
    redirect: '/03/menu03Screen015/search',
    children: [
      {
        path: 'search',
        name: 'generatedRoleSearch',
        component: GeneratedRoleSearch
      },
      {
        path: 'allocation',
        name: 'generatedRoleAllocationSearch',
        component: GeneratedRoleAllocationSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen016',
    component: DashboardLayout,
    redirect: '/03/menu03Screen016/search',
    children: [
      {
        path: 'search',
        name: 'generatedEmployeeRoleAllocationSearch',
        component: GeneratedEmployeeRoleAllocationSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen020',
    component: DashboardLayout,
    redirect: '/03/menu03Screen020/search',
    children: [
      {
        path: 'search',
        name: 'generatedGroundSheet',
        component: GeneratedGroundSheet
      }
    ]
  },
  {
    path: '/03/menu03Screen021',
    component: DashboardLayout,
    redirect: '/03/menu03Screen021/search',
    children: [
      {
        path: 'search',
        name: 'generatedPlaceSearch',
        component: GeneratedPlaceSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen022',
    component: DashboardLayout,
    redirect: '/03/menu03Screen022/search',
    children: [
      {
        path: 'search',
        name: 'generatedClosingSearch',
        component: GeneratedClosingSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen023',
    component: DashboardLayout,
    redirect: '/03/menu03Screen023/search',
    children: [
      {
        path: 'search',
        name: 'generatedEmployeeClassificationSearch',
        component: GeneratedEmployeeClassificationSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen024',
    component: DashboardLayout,
    redirect: '/03/menu03Screen024/search',
    children: [
      {
        path: 'search',
        name: 'employeeRoundingSearch',
        component: EmployeeRoundingSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen027',
    component: DashboardLayout,
    redirect: '/03/menu03Screen027/search',
    children: [
      {
        path: 'search',
        name: 'generatedApplicationClassificationSearch',
        component: GeneratedApplicationClassificationSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen028',
    component: DashboardLayout,
    redirect: '/03/menu03Screen028/search',
    children: [
      {
        path: 'search',
        name: 'generatedLunchOrder',
        component: GeneratedLunchOrder
      }
    ]
  },
  {
    path: '/03/menu03Screen031',
    component: DashboardLayout,
    redirect: '/03/menu03Screen031/search',
    children: [
      {
        path: 'search',
        name: 'generatedUnionSearch',
        component: GeneratedUnionSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen033',
    component: DashboardLayout,
    redirect: '/03/menu03Screen033/search',
    children: [
      {
        path: 'search',
        name: 'generatedPaidAnnualAccountEmployeeSearch',
        component: GeneratedPaidAnnualAccountEmployeeSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen035',
    component: DashboardLayout,
    redirect: '/03/menu03Screen035/search',
    children: [
      {
        path: 'search',
        name: 'generatedLunchOrderResultListSearch',
        component: GeneratedLunchOrderResultListSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen036',
    component: DashboardLayout,
    redirect: '/03/menu03Screen036/search',
    children: [
      {
        path: 'search',
        name: 'generatedRecruitmentCategorySearch',
        component: GeneratedRecruitmentCategorySearch
      }
    ]
  },
  {
    path: '/03/menu03Screen042',
    component: DashboardLayout,
    redirect: '/03/menu03Screen042/search',
    children: [
      {
        path: 'search',
        name: 'generatedShiftPatternSearch',
        component: GeneratedShiftPatternSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen001',
    component: DashboardLayout,
    redirect: '/03/menu03Screen001/view',
    children: [
      {
        path: 'view',
        name: 'companyView',
        component: CompanyView
      }
    ]
  },
  {
    path: '/03',
    component: DashboardLayout,
    redirect: '/03/menu03Screen001/view',
    children: [
      {
        path: 'menu03Screen001/view',
        name: 'companyView2',
        component: CompanyView
      }
    ]
  },
  {
    path: '/03/menu03Screen005',
    component: DashboardLayout,
    redirect: '/03/menu03Screen005/search',
    children: [
      {
        path: 'search',
        name: 'employee',
        component: EmployeeSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen006',
    component: DashboardLayout,
    redirect: '/03/menu03Screen006/view',
    children: [
      {
        path: 'view',
        name: 'generatedPaidHolidayManagement',
        component: GeneratedPaidHolidayManagement
      }
    ]
  },
  {
    path: '/03/menu03Screen007',
    component: DashboardLayout,
    redirect: '/03/menu03Screen007/search',
    children: [
      {
        path: 'search',
        name: 'generatedPaidHolidayPayment',
        component: GeneratedPaidHolidayPayment
      }
    ]
  },
  {
    path: '/03/menu03Screen008',
    component: DashboardLayout,
    redirect: '/03/menu03Screen008/search',
    children: [
      {
        path: 'search',
        name: 'salaryItem',
        component: SalaryItem
      }
    ]
  },
  {
    path: '/03/menu03Screen009',
    component: DashboardLayout,
    redirect: '/03/menu03Screen009/search',
    children: [
      {
        path: 'search',
        name: 'rate',
        component: Rate
      }
    ]
  },
  {
    path: '/03/menu03Screen010',
    component: DashboardLayout,
    redirect: '/03/menu03Screen010/search',
    children: [
      {
        path: 'search',
        name: 'paySlipLayout',
        component: PaySlipLayout
      }
    ]
  },
  {
    path: '/03/menu03Screen011',
    component: DashboardLayout,
    redirect: '/03/menu03Screen011/search',
    children: [
      {
        path: 'search',
        name: 'bonusLayout',
        component: BonusLayout
      }
    ]
  },
  {
    path: '/03/menu03Screen012',
    component: DashboardLayout,
    redirect: '/03/menu03Screen012/search',
    children: [
      {
        path: 'search',
        name: 'taskSearch',
        component: TaskSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen017',
    component: DashboardLayout,
    redirect: '/03/menu03Screen017/search',
    children: [
      {
        path: 'search',
        name: 'generatedApprovalRouteSearch',
        component: GeneratedApprovalRouteSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen018',
    component: DashboardLayout,
    redirect: '/03/menu03Screen018/search',
    children: [
      {
        path: 'search',
        name: 'generatedApplicationFormSearch',
        component: GeneratedApplicationFormSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen019',
    component: DashboardLayout,
    redirect: '/03/menu03Screen019/search',
    children: [
      {
        path: 'search',
        name: 'generatedAuthority',
        component: GeneratedAuthority,
        props: true
      }
    ]
  },
  {
    path: '/03/menu03Screen034',
    component: DashboardLayout,
    redirect: '/03/menu03Screen034/search',
    children: [
      {
        path: 'search',
        name: 'parlanceSearch',
        component: ParlanceSearch,
        props: true
      }
    ]
  },
  {
    path: '/03/menu03Screen026',
    component: DashboardLayout,
    redirect: '/03/menu03Screen026/search',
    children: [
      {
        path: 'search',
        name: 'generatedTeamSearch',
        component: GeneratedTeamSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen029',
    component: DashboardLayout,
    redirect: '/03/menu03Screen029/search',
    children: [
      {
        path: 'search',
        name: 'generatedGroupSearch',
        component: GeneratedGroupSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen030',
    component: DashboardLayout,
    redirect: '/03/menu03Screen030/search',
    children: [
      {
        path: 'search',
        name: 'generatedSalaryClosingSearch',
        component: GeneratedSalaryClosingSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen032',
    component: DashboardLayout,
    redirect: '/03/menu03Screen032/search',
    children: [
      {
        path: 'search',
        name: 'generatedJobTitleSearch',
        component: GeneratedJobTitleSearch
      }
    ]
  },
  {
    path: '/03/menu03Screen039',
    component: DashboardLayout,
    redirect: '/03/menu03Screen039/search',
    children: [
      {
        path: 'search',
        name: 'forgotImprint',
        component: ForgotImprint
      }
    ]
  },
  {
    path: '/03/menu03Screen043',
    component: DashboardLayout,
    redirect: '/03/menu03Screen043/search',
    children: [
      {
        path: 'search',
        name: 'customerSearch',
        component: CustomerSearch
      },
      {
        path: 'customerDepartment',
        name: 'customerDepartment',
        component: CustomerDepartment
      }
    ]
  },
  {
    path: '/03/menu03Screen044',
    component: DashboardLayout,
    redirect: '/03/menu03Screen044/search',
    children: [
      {
        path: 'search',
        name: 'supplierSearch',
        component: SupplierSearch
      },
      {
        path: 'supplierDepartment',
        name: 'supplierDepartment',
        component: SupplierDepartment
      }
    ]
  },
  {
    path: '/03/menu03Screen045',
    component: DashboardLayout,
    redirect: '/03/menu03Screen045/search',
    children: [
      {
        path: 'search',
        name: 'productSearch',
        component: ProductSearch
      }
    ]
  },
  {
    path: '/04/menu04Screen001',
    component: DashboardLayout,
    redirect: '/04/menu04Screen001/new',
    children: [
      {
        path: 'new',
        name: 'workflowAddressChange',
        component: Workflow,
        props: {
          applicationFormCode: '0000000018',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen002',
    component: DashboardLayout,
    redirect: '/04/menu04Screen002/new',
    children: [
      {
        path: 'new',
        name: 'workflowCommutingRouteChange',
        component: Workflow,
        props: {
          applicationFormCode: '0000000019',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen003',
    component: DashboardLayout,
    redirect: '/04/menu04Screen003/new',
    children: [
      {
        path: 'new',
        name: 'workflowPersonalInformationChange',
        component: Workflow,
        props: {
          applicationFormCode: '0000000020',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen004',
    component: DashboardLayout,
    redirect: '/04/menu04Screen004/new',
    children: [
      {
        path: 'new',
        name: 'workflowAttendanceRecord',
        component: Workflow,
        props: {
          applicationFormCode: '0000000016',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen005',
    component: DashboardLayout,
    redirect: '/04/menu04Screen005/new',
    children: [
      {
        path: 'new',
        name: 'workflowStampTime',
        component: Workflow,
        props: {
          applicationFormCode: '0000000001',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen006',
    component: DashboardLayout,
    redirect: '/04/menu04Screen006/new',
    children: [
      {
        path: 'new',
        name: 'workflowLateEarlyDepartureTime',
        component: Workflow,
        props: {
          applicationFormCode: '0000000005',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen007',
    component: DashboardLayout,
    redirect: '/04/menu04Screen007/new',
    children: [
      {
        path: 'new',
        name: 'workflowOvertime',
        component: Workflow,
        props: {
          applicationFormCode: '0000000002',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen008',
    component: DashboardLayout,
    redirect: '/04/menu04Screen008/new',
    children: [
      {
        path: 'new',
        name: 'workflowLateNightOverwork',
        component: Workflow,
        props: {
          applicationFormCode: '0000000004',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen009',
    component: DashboardLayout,
    redirect: '/04/menu04Screen009/new',
    children: [
      {
        path: 'new',
        name: 'workflowHolidayWork',
        component: Workflow,
        props: {
          applicationFormCode: '0000000007',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen010',
    component: DashboardLayout,
    redirect: '/04/menu04Screen010/new',
    children: [
      {
        path: 'new',
        name: 'workflowTransferHolidayWork',
        component: Workflow,
        props: {
          applicationFormCode: '0000000011',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen011',
    component: DashboardLayout,
    redirect: '/04/menu04Screen011/new',
    children: [
      {
        path: 'new',
        name: 'workflowTimeLimitExceeded',
        component: Workflow,
        props: {
          applicationFormCode: '0000000008',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen012',
    component: DashboardLayout,
    redirect: '/04/menu04Screen012/new',
    children: [
      {
        path: 'new',
        name: 'workflowLeaveJob',
        component: Workflow,
        props: {
          applicationFormCode: '0000000014',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen013',
    component: DashboardLayout,
    redirect: '/04/menu04Screen013/new',
    children: [
      {
        path: 'new',
        name: 'workflowGround',
        component: Workflow,
        props: {
          applicationFormCode: '0000000003',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/04/menu04Screen014',
    component: DashboardLayout,
    redirect: '/04/menu04Screen014/new',
    children: [
      {
        path: 'new',
        name: 'workflowHoliday',
        component: Workflow,
        props: {
          applicationFormCode: '0000000009',
          isModal: false
        }
      }
    ]
  },
  {
    path: '/05/menu05Screen002',
    component: DashboardLayout,
    redirect: '/05/menu05Screen002/search',
    children: [
      {
        path: 'search',
        name: 'generatedGroupDivisionSearch',
        component: GeneratedGroupDivisionSearch
      }
    ]
  },
  {
    path: '/05/menu05Screen003',
    component: DashboardLayout,
    redirect: '/05/menu05Screen003/search',
    children: [
      {
        path: 'search',
        name: 'generatedGroupManagementSearch',
        component: GeneratedGroupManagementSearch
      }
    ]
  },
  {
    path: '/06',
    component: DashboardLayout,
    redirect: '/06/menu06Screen001',
    children: [
      {
        path: 'menu06Screen001',
        name: 'closingAttendanceRecord',
        component: ClosingAttendanceRecord
      }
    ]
  },
  {
    path: '/06',
    component: DashboardLayout,
    redirect: '/06/menu06Screen002/search',
    children: [
      {
        path: 'menu06Screen002',
        name: 'changeSocialInsuranceSearch',
        component: ChangeSocialInsuranceSearch
      }
    ]
  },
  {
    path: '/06',
    component: DashboardLayout,
    redirect: '/06/menu06Screen004',
    children: [
      {
        path: 'menu06Screen004',
        name: 'shiftSettingSearch',
        component: ShiftSettingSearch
      }
    ]
  },
  {
    path: '/06',
    component: DashboardLayout,
    redirect: '/06/menu06Screen005',
    children: [
      {
        path: 'menu06Screen005',
        name: 'desiredShiftConfirmSearch',
        component: DesiredShiftConfirmSearch
      }
    ]
  },
  {
    path: '/06',
    component: DashboardLayout,
    redirect: '/06/menu06Screen006',
    children: [
      {
        path: 'menu06Screen006',
        name: 'shiftDecisionSearch',
        component: ShiftDecisionSearch
      }
    ]
  },
  {
    path: '/07',
    component: DashboardLayout,
    redirect: '/07/menu07Screen001/search',
    children: [
      {
        path: 'menu07Screen001',
        name: 'annualCalendarSearch',
        component: AnnualCalendarSearch
      }
    ]
  },
  {
    path: '/07',
    component: DashboardLayout,
    redirect: '/07/menu07Screen002',
    children: [
      {
        path: 'menu07Screen002',
        name: 'agreement36Search',
        component: LegalRuleSearch
      }
    ]
  },
  {
    path: '/07',
    component: DashboardLayout,
    redirect: '/07/menu07Screen003',
    children: [
      {
        path: 'menu07Screen003',
        name: 'generatedTransformationWeeklySearch',
        component: GeneratedTransformationWeeklySearch
      }
    ]
  },
  {
    path: '/07',
    component: DashboardLayout,
    redirect: '/07/menu07Screen004',
    children: [
      {
        path: 'menu07Screen004',
        name: 'generatedTransformationMonthlySearch',
        component: GeneratedTransformationMonthlySearch
      }
    ]
  },
  {
    path: '/07',
    component: DashboardLayout,
    redirect: '/07/menu07Screen005',
    children: [
      {
        path: 'menu07Screen005',
        name: 'generatedTransformationYearlySearch',
        component: GeneratedTransformationYearlySearch
      }
    ]
  },
  {
    path: '/07',
    component: DashboardLayout,
    redirect: '/07/menu07Screen010',
    children: [
      {
        path: 'menu07Screen010',
        name: 'calculationBasisSearch',
        component: CalculationBasisSearch
      }
    ]
  },
  {
    path: '/08',
    component: DashboardLayout,
    redirect: '/08/menu08Screen001',
    children: [
      {
        path: 'menu08Screen001',
        name: 'generatedWorkersListSearch',
        component: GeneratedWorkersListSearch
      },
      {
        path: 'menu08Screen002',
        name: 'generatedPaySlipsListSearch',
        component: GeneratedPaySlipsListSearch
      },
      {
        path: 'menu08Screen003',
        name: 'generatedWageLedgerSearch',
        component: GeneratedWageLedgerSearch
      },
      {
        path: 'menu08Screen004',
        name: 'generatedResidentTaxSearch',
        component: GeneratedResidentTaxSearch
      },
      {
        path: 'menu08Screen005',
        name: 'generatedEmployeeAge',
        component: GeneratedEmployeeAge
      },
      {
        path: 'menu08Screen009',
        name: 'generatedBonusDetailsSearch',
        component: GeneratedBonusDetailsSearch
      }
    ]
  },
  {
    path: '/08/menu08Screen007',
    component: DashboardLayout,
    redirect: '/08/menu08Screen007/search',
    children: [
      {
        path: 'search',
        name: 'ledgerReportList',
        component: ReportList
      }
    ]
  },
  {
    path: '/08/menu08Screen010',
    component: DashboardLayout,
    redirect: '/08/menu08Screen010/search',
    children: [
      {
        path: 'search',
        name: 'ledgerUploadList',
        component: UploadList
      }
    ]
  },
  {
    path: '/09/menu09Screen001',
    component: DashboardLayout,
    redirect: '/09/menu09Screen001/search',
    children: [
      {
        path: 'search',
        name: 'generatedSalaryIncreaseSimulationSearch',
        component: GeneratedSalaryIncreaseSimulationSearch
      }
    ]
  },
  {
    path: '/09/menu09Screen011',
    component: DashboardLayout,
    redirect: '/09/menu09Screen011/search',
    children: [
      {
        path: 'search',
        name: 'legalRuleSurveillanceSearch',
        component: LegalRuleSurveillanceSearch
      },
      {
        path: 'dayData',
        name: 'legalRuleSurveillanceDayData',
        component: LegalRuleSurveillanceDayData
      }
    ]
  },
  {
    path: '/09/menu09Screen012',
    component: DashboardLayout,
    redirect: '/09/menu09Screen012/search',
    children: [
      {
        path: 'search',
        name: 'stagnationSearch',
        component: StagnationSearch
      }
    ]
  },
  {
    path: '/09/menu09Screen017',
    component: DashboardLayout,
    redirect: '/09/menu09Screen017/search',
    children: [
      {
        path: 'search',
        name: 'score',
        component: Score
      }
    ]
  },
  {
    path: '/11',
    component: DashboardLayout,
    redirect: '/11/menu11Screen001',
    children: [
      {
        path: 'menu11Screen001',
        name: 'changePassword',
        component: ChangePassword
      }
    ]
  },
  {
    path: '/11/menu11Screen002',
    component: DashboardLayout,
    redirect: '/11/menu11Screen002/search',
    children: [
      {
        path: 'search',
        name: 'proxyApprovalSetting',
        component: ProxyApprovalSetting
      }
    ]
  },
  {
    path: '/11/menu11Screen003',
    component: DashboardLayout,
    redirect: '/11/menu11Screen003/search',
    children: [
      {
        path: 'search',
        name: 'generatedSearchScreen',
        component: GeneratedSearchScreen
      }
    ]
  },
  {
    path: '/11/menu11Screen004',
    component: DashboardLayout,
    redirect: '/11/menu11Screen004/search',
    children: [
      {
        path: 'search',
        name: 'boss',
        component: Boss
      }
    ]
  },
  {
    path: '/11/menu11Screen005',
    component: DashboardLayout,
    redirect: '/11/menu11Screen005/search',
    children: [
      {
        path: 'search',
        name: 'noticeSearch',
        component: NoticeSearch
      }
    ]
  },
  {
    path: '/11/menu11Screen006',
    component: DashboardLayout,
    redirect: '/11/menu11Screen006/search',
    children: [
      {
        path: 'search',
        name: 'employeeAlertManagement',
        component: EmployeeAlertManagement
      }
    ]
  },
  {
    path: '/11/menu11Screen007',
    component: DashboardLayout,
    redirect: '/11/menu11Screen007/search',
    children: [
      {
        path: 'search',
        name: 'employeeArtemisAlertManagement',
        component: EmployeeArtemisAlertManagement
      }
    ]
  },
  {
    path: '/11/menu11Screen903',
    component: DashboardLayout,
    redirect: '/11/menu11Screen903/search',
    children: [
      {
        path: 'search',
        name: 'generatedSearchScreen2',
        component: GeneratedSearchScreen
      }
    ]
  },
  {
    path: '/12/menu12Screen002',
    component: DashboardLayout,
    redirect: '/12/menu12Screen002/search',
    children: [
      {
        path: 'search',
        name: 'vaccinationSearch',
        component: Vaccination
      }
    ]
  },
  {
    path: '/13/menu13Screen001',
    component: DashboardLayout,
    redirect: '/13/menu13Screen001/search',
    children: [
      {
        path: 'search',
        name: 'generatedWorkTypeSearch',
        component: GeneratedWorkTypeSearch
      }
    ]
  },
  {
    path: '/13/menu13Screen004',
    component: DashboardLayout,
    redirect: '/13/menu13Screen004/search',
    children: [
      {
        path: 'search',
        name: 'quotationSearch',
        component: QuotationSearch
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen004',
    children: [
      {
        path: 'menu99Screen004',
        name: 'generatedOperationNoticeSearch',
        component: GeneratedOperationNoticeSearch
      },
      {
        path: 'confirmation',
        name: 'confirmationSearch',
        component: NoticeConfirmation
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen005',
    children: [
      {
        path: 'menu99Screen005',
        name: 'withholdingTaxAmount',
        component: WithholdingTaxAmount
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen006',
    children: [
      {
        path: 'menu99Screen006',
        name: 'generatedPublicHolidaySearch',
        component: GeneratedPublicHolidaySearch
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen007',
    children: [
      {
        path: 'menu99Screen007',
        name: 'applicationFormManagement',
        component: ApplicationFormManagement
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen008',
    children: [
      {
        path: 'menu99Screen008',
        name: 'generatedScreenSearch',
        component: GeneratedScreenSearch
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen009',
    children: [
      {
        path: 'menu99Screen009',
        name: 'generatedLanguageSearch',
        component: GeneratedLanguageSearch
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen010',
    children: [
      {
        path: 'menu99Screen010',
        name: 'generatedMessageSearch',
        component: GeneratedMessageSearch
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen011',
    children: [
      {
        path: 'menu99Screen011',
        name: 'applicationClassificationManagement',
        component: ApplicationClassificationManagement
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen012',
    children: [
      {
        path: 'menu99Screen012',
        name: 'generatedRreportSearch',
        component: GeneratedReportSearch
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen013',
    children: [
      {
        path: 'menu99Screen013',
        name: 'generatedCustomerCompanySearch',
        component: GeneratedCustomerCompanySearch
      }
    ]
  },
  {
    path: '/99',
    component: DashboardLayout,
    redirect: '/99/menu99Screen014',
    children: [
      {
        path: 'menu99Screen014',
        name: 'generatedCsvTemplateFormatSearch',
        component: GeneratedCsvTemplateFormatSearch
      }
    ]
  },
  {
    path: '/DB',
    component: DashboardLayout,
    redirect: '/DB/menuDBScreen012',
    children: [
      {
        path: 'menuDBScreen012',
        name: 'reportList',
        component: ReportList
      }
    ]
  },
  {
    path: '/SS',
    component: DashboardLayout,
    redirect: '/SS/menuSSScreen021',
    children: [
      {
        path: 'menuSSScreen021',
        name: 'employeeInfo',
        component: EmployeeInfo
      }
    ]
  },
  {
    path: '/SS',
    component: DashboardLayout,
    redirect: '/SS/menuSSScreen022',
    children: [
      {
        path: 'menuSSScreen022',
        name: 'deputyApproverInfo',
        component: DeputyApproverInfo
      }
    ]
  },
  {
    path: '/admin/login',
    name: 'adminLogin',
    component: AdminLogin
  },
  {
    path: '/user',
    component: UserDashboardLayout,
    redirect: '/user/menu',
    children: [
      {
        path: '/user/menu',
        name: 'adminMenu',
        component: AdminMenu
      },
      {
        path: '/user/payment',
        name: 'payment',
        component: Payment
      },
      {
        path: '/user/mail_address_change',
        name: 'mailAddressChange',
        component: MailAddressChange
      },
      {
        path: '/user/password_change',
        name: 'userPasswordChange',
        component: UserPasswordChange
      },
      {
        path: '/user/company-regist',
        name: 'userCompanyRegist',
        component: CompanyRegist
      },
      {
        path: '/user/company-wizard',
        name: 'userCompanyWizard',
        component: CompanyWizardForm
      },
      {
        path: '/user/credit_card',
        name: 'updateCreditCard',
        component: UpdateCreditCard
      },
      {
        path: '/user/invoice',
        name: 'invoice',
        component: Invoice
      },
      {
        path: '/user/delete_company',
        name: 'deleteCompany',
        component: DeleteCompany
      }
    ]
  },
  {
    path: '/private-update-access-mail-address',
    name: 'mailAddressComplete',
    component: MailAddressComplete
  },
  {
    path: '/qrcode/login',
    name: 'qrcodeLogin',
    component: QrcodeLogin
  },
  {
    path: '/qrcode/stamp',
    name: 'qrcodeStamp',
    component: QrcodeStamp
  },
  {
    path: '/migration/start',
    name: 'start',
    component: Start
  },
  {
    path: '/migration/agreement',
    name: 'agreement',
    component: Agreement
  },
  {
    path: '/migration/wizard',
    name: 'wizardForm',
    component: WizardForm
  },
  {
    path: '/migration/waiting',
    name: 'waiting',
    component: Waiting
  },
  {
    path: '/migration/paymentComplete',
    name: 'paymentComplete',
    component: PaymentComplete
  },
  {
    path: '/general/internalServerError',
    name: 'internalServerError',
    component: InternalServerError
  },
  { path: '*', component: NotFound }
]

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
 function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes
