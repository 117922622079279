<template>
  <div>
    <div style="position: absolute; left: 0; width: 100vw; height: 100vh; background-color: white; z-index: 10;" v-if="!initialized"></div>
    <form method="#" action="#" @submit.prevent="">
      <button type="button" class="btn btn-sm btn-default btn-danger" @click="doClose">
        <span class="btn-label"><i class="ti-close"></i></span>
        {{$t('button.close')}}
      </button>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title" style="margin: 0;">
            {{screenCodeTitle(screenCode)}}
          </h4>
          <Help :help="help" :placeholders="[pageTitle]" div-style="padding-bottom: 5px;"/>
          <MessageArea :page-type="message && message.message_classification === 2 ? pageType.proxyApproval : pageType.detail" :message="message" :placeholders="[pageTitle]"/>
        </div>
        <div class="card-content" ref="frame"><div :key="componentKey">
          <ValidationObserver ref="observer">
            <ValidationText
              v-model="detail.working_item_name"
              item-name="working_item_name"
              max-length="30"
              classes="form-control length60"
              rules="required"
              :item-label="labels.working_item_name"
              :placeholder="isReadOnly ? '' : $t('placeholder.working_item_name')"
              :disabled="isReadOnly"
              :help-text="$t('label.max_length')"
            />
            <ValidationSelect
              v-model="detail.working_item_unit"
              item-name="working_item_unit"
              :placeholder="$t('label.select_description')"
              classes="length"
              :item-label="labels.working_item_unit"
              :options="comboData.working_item_unit_list"
              :disabled="isReadOnly"
              @change="workingItemUnitChanged"
            />
            <CsvFormula
              v-model="detail.formula"
              item-name="formula"
              classes="form-control"
              :item-label="labels.formula"
              :combo-data="comboData"
              :disabled="isReadOnly"
              :labels="labels"
            />
          </ValidationObserver>
        </div></div>
      </div>
      <template v-if="mode==modes.add && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doAdd">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.add')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.edit && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" :style="isProcessing ? 'pointer-events: none;' : ''" @click="doUpdate">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.update')}}
          </button>
        </div>
      </template>
      <template v-if="mode==modes.delete && !this.isChanged">
        <div style="text-align: left;">
          <button type="button" class="btn btn-sm btn-default btn-submit" @click="doDelete">
            <span class="btn-label"><i class="fa fa-check"></i></span>
            {{$t('button.delete')}}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>
<script>
  import CsvOutputItemDetailMixin from '@/components/Dashboard/Views/Generated/CsvOutputItemDetailMixin'

  export default {
    name: 'CsvOutputItemDetail',
    methods: {
      afterInit (res) {
        this.comboData.working_item_unit_list.unshift({label: '', value: ''})
        if (this.detail.formula) {
          for (const row of this.comboData.working_item_all_list) {
            this.detail.formula = this.detail.formula.replaceAll('{' + row.value + '}', '[' + row.label + ']')
          }
        }
      },
      convertDetail (detail) {
        if (this.mode === this.modes.add) {
          delete detail.working_item_id
        } else if (this.mode === this.modes.delete) {
          detail.working_item_id = this.detail.working_item_id
        }
        if (detail.formula) {
          for (const row of this.comboData.working_item_all_list) {
            detail.formula = detail.formula.replaceAll('[' + row.label + ']', '{' + row.value + '}')
          }
        }
        delete detail.template
        return detail
      }
    },
    mixins: [CsvOutputItemDetailMixin]
  }
</script>
<style scoped>
</style>
