import moment from 'moment'
import apiMixin from '@/lib/apiMixin'
import validationMixin from '@/lib/validationMixin'

export default {
  mixins: [apiMixin, validationMixin],
  beforeCreate () {
    moment.locale('ja')
    this.$sidebar.sidebarLinks = this.$store.state.loginUser.sidebarLinks
    this.$sidebar.userSidebarLinks = this.$store.state.userLoginUser.sidebarLinks
  },
  computed: {
    isProd () {
      return process.env.VUE_APP_ENV === 'production'
    },
    isTest () {
      return process.env.VUE_APP_ENV === 'testing'
    },
    pageTitle () {
      for (let menu of this.$store.state.loginUser.sidebarLinks) {
        for (let child of menu.children) {
          if (this.$route.path.startsWith(child.path)) {
            return child.name
          }
        }
      }
      return ''
    },
    screenCode () {
      for (let menu of this.$store.state.loginUser.sidebarLinks) {
        for (let child of menu.children) {
          if (this.$route.path.startsWith(child.path)) {
            return child.code
          }
        }
      }
      for (let db of this.$store.state.dbList) {
        if (this.$route.path.startsWith(db.path)) {
          return db.code
        }
      }
      return ''
    }
  },
  methods: {
    screenCodeTitle (screenCode) {
      if (window.currentUrl === '/03/menu03Screen014/lunch') {
        return this.$t('label.lunch')
      }
      for (let menu of this.$store.state.loginUser.sidebarLinks) {
        for (let child of menu.children) {
          if (screenCode === child.code) {
            return child.name
          }
        }
      }
      return ''
    },
    isHankaku (str) {
      str = (str == null) ? '' : str
      if (str.match(/^[\x20-\x7e]*$/)) {
        return true
      } else {
        return false
      }
    },
    zenkaku2Hankaku (str) {
      let hankaku = str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
      })
      return hankaku.replace('：', ':')
    },
    /**
     * 文字列を数値に変換する。
     * @param strNumber 文字列
     * @returns 数値
     */
    covertNumber (strNumber) {
      if (strNumber === undefined && strNumber === null) {
        return null
      }
      return parseInt(String(strNumber).replaceAll(',', ''), 10)
    },
    /**
     * 数値を文字列に変換する。
     * @param number 数値
     * @returns 文字列(カンマ付与)
     */
    formatNumber (number) {
      return number ? parseInt(number, 10).toLocaleString() : number === 0 ? '0' : ''
    },
    formatFloat (number, fractionDigit) {
      return number ? parseFloat(number).toLocaleString(undefined, {minimumFractionDigits: fractionDigit, maximumFractionDigits: fractionDigit}) : number
    },
    formatNow () {
      return moment(Date.now()).format('YYYY/MM/DD HH:mm:ss')
    },
    formatToday () {
      return this.formatTodayWith('YYYY/MM/DD')
    },
    formatTodayWith (format) {
      return moment(Date.now()).format(format)
    },
    formatNowMinute () {
      return moment(Date.now()).format('YYYY/MM/DD HH:mm')
    },
    // 月初
    formatBeginMonth () {
      let date = new Date()
      return moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY/MM/DD')
    },
    // 月末
    formatEndMonth () {
      let date = new Date()
      return moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format('YYYY/MM/DD')
    },
    formatDate (date) {
      return date === null ? null : moment(date).format('YYYY/MM/DD')
    },
    formatDateWith (date, format) {
      return date === null ? null : moment(date).format(format)
    },
    formatDispDateWithDateOfWeek (date, labelYear, labelMonth, labelDay, labelWeekday) {
      let yyyyMMdd = date.split('/')
      return `${yyyyMMdd[0]}${labelYear}${yyyyMMdd[1]}${labelMonth}${yyyyMMdd[2]}${labelDay}(${labelWeekday})`
    },
    /**
     * 西暦を和暦に変換
     * @param {string} targetDate 対象年月日(yyyy、yyyymm、yyyymmdd、yyyy/mm/dd)
     * @param {string} era 'narrow'(アルファベット)、'long'(漢字)
     * @return jpDate 和暦
     */
    seirekiToWareki (targetDate, era) {
      let options = {era: era, year: 'numeric'}
      // yyyyの場合、0101を結合
      if (targetDate.length === 4) {
        targetDate = targetDate + '0101'
        // yyyymmの場合、01を結合、optionsに月を追加
      } else if (targetDate.length === 6) {
        targetDate = targetDate + '01'
        options.month = 'narrow'
        // yyyymmddの場合、optionsに月日を追加
      } else if (targetDate.length === 8) {
        options.month = 'narrow'
        options.day = 'numeric'
        // yyyy/mm/ddの場合、数値以外を除いてoptionsに月日を追加
      } else if (targetDate.length === 10) {
        targetDate = targetDate.replace(/[^0-9]/g, '')
        options.month = 'narrow'
        options.day = 'numeric'
      } else {
        return ''
      }
      let year = parseInt(targetDate.substring(0, 4))
      let month = parseInt(targetDate.substring(4, 6))
      let day = parseInt(targetDate.substring(6, 8))
      let date = new Date(year, month - 1, day)
      let jpDate = new Intl.DateTimeFormat('ja-JP-u-ca-japanese', options).format(date)
      return jpDate
    },
    formatDispHour (hours) {
      return this.formatDispMinute(hours * 60)
    },
    formatDispMinute (minutes) {
      if (minutes === 0) return '0:00'
      if (!minutes) return minutes
      let plusMinutes = Math.abs(minutes ? minutes.toFixed(0) : minutes)
      const hour = parseInt(plusMinutes / 60, 10).toLocaleString()
      const minute = plusMinutes % 60
      let dispMinute = hour + ':' + ('0' + minute).slice(-2)
      if (minutes < 0) {
        dispMinute = '-' + dispMinute
      }
      return dispMinute
    },
    formatDispMinuteWithUnit (minutes) {
      let plusMinutes = Math.abs(minutes ? minutes.toFixed(0) : minutes)
      const hour = parseInt(plusMinutes / 60, 10).toLocaleString()
      const minute = plusMinutes % 60
      if (hour === '0') return ''
      let dispMinute = hour + this.$t('label.unit_hours')
      if (minutes < 0) {
        dispMinute = '-' + dispMinute
      }
      if (minute === 0) return dispMinute
      dispMinute += ('0' + minute).slice(-2) + this.$t('label.unit_minutes')
      return dispMinute
    },
    formatDispMinuteWithUnitForHours (hours) {
      if (hours === 0) return ''
      return hours + this.$t('label.unit_hours')
    },
    formatDispTimeRange (from, to, hours) {
      const fromHour = parseInt(from.split(':')[0], 10)
      const toHour = parseInt(to.split(':')[0], 10)
      let dispTimeRange = this.formatDispMinuteWithUnit(hours * 60)
      dispTimeRange += '('
      dispTimeRange += fromHour + ':' + from.split(':')[1]
      dispTimeRange += '-'
      dispTimeRange += toHour + ':' + to.split(':')[1]
      dispTimeRange += ')'
      return dispTimeRange
    },
    toMinutes (time) {
      if (!time) return 0
      if (time.length === 4) time = '0' + time
      if (time.length < 5) return 0
      const hour = parseInt(time.substring(0, 2), 10)
      const minute = parseInt(time.substring(3, 5), 10)
      return hour * 60 + minute
    },
    formatDispYearMonth (yyyyMM, labelYear, labelMonth) {
      if (yyyyMM === undefined || yyyyMM === null || yyyyMM === '') {
        return yyyyMM
      }
      const year = yyyyMM.substring(0, 4)
      const month = parseInt(yyyyMM.substring(4, 6), 10)
      return year + labelYear + month + labelMonth
    },
    formatDispMonth (yyyyMM, labelMonth) {
      if (yyyyMM === undefined || yyyyMM === null || yyyyMM === '') {
        return yyyyMM
      }
      const month = parseInt(yyyyMM.substring(4, 6), 10)
      return month + labelMonth
    },
    addDateWith (date, addDate, unit) {
      return moment(date).add(addDate, unit).format('YYYY/MM/DD')
    },
    calcYears (from, to) {
      const fromList = from.split('/')
      const toList = to.split('/')
      const fromDate = moment(new Date(fromList[0], parseInt(fromList[1], 10) - 1, fromList[2]))
      const toDate = moment(new Date(toList[0], parseInt(toList[1] - 1), toList[2]))
      return toDate.diff(fromDate, 'years')
    },
    /**
     * 稼働時間を返却する。
     * @param startTime 開始時間(hh:mm)
     * @param endTime 終了時間(hh:mm)
     * @param breakTime 休憩時間(休憩開始-休憩終了の配列[hh:mm-hh:mm, hh:mm-hh:mm, …])
     * @returns 稼働時間
     */
    calcTimeToHour (startTime, endTime, breakTimeList) {
      if (startTime.length === 4) startTime = '0' + startTime
      if (endTime.length === 4) endTime = '0' + endTime
      if (startTime.length !== 5 || endTime.length !== 5) return ''

      const startTimeHour = parseInt(startTime.substring(0, 2), 10)
      const startTimeMinute = parseInt(startTime.substring(3, 5), 10)
      const inStartTime = startTimeHour * 60 + startTimeMinute

      const endTimeHour = parseInt(endTime.substring(0, 2), 10)
      const endTimeMinute = parseInt(endTime.substring(3, 5), 10)
      const inEndTime = endTimeHour * 60 + endTimeMinute

      let ans = inEndTime - inStartTime
      // 休憩時間
      for (let startEndBreakTime of breakTimeList) {
        let breakTime = startEndBreakTime.split('-')
        let startBreakTime = breakTime[0]
        let endBreakTime = breakTime[1]
        if (startBreakTime.length === 4) startBreakTime = '0' + startBreakTime
        if (endBreakTime.length === 4) endBreakTime = '0' + endBreakTime

        // 開始時間～終了時間の間以外の休憩時間の場合、休憩時間を引かない
        if (startTime > startBreakTime || endTime < endBreakTime) {
          continue
        }

        const startBreakTimeHour = parseInt(startBreakTime.substring(0, 2), 10)
        const startBreakTimeMinute = parseInt(startBreakTime.substring(3, 5), 10)
        const instartBreakTime = startBreakTimeHour * 60 + startBreakTimeMinute

        const endBreakTimeHour = parseInt(endBreakTime.substring(0, 2), 10)
        const endBreakTimeMinute = parseInt(endBreakTime.substring(3, 5), 10)
        const inendBreakTime = endBreakTimeHour * 60 + endBreakTimeMinute

        const inBreakTime = inendBreakTime - instartBreakTime
        ans = ans - inBreakTime
      }
      return ans / 60
    },
    calcTime (from, to) {
      if (from.length === 4) from = '0' + from
      if (to.length === 4) to = '0' + to
      if (from.length !== 5 || to.length !== 5) return ''

      const fromHour = parseInt(from.substring(0, 2), 10)
      const fromMinute = parseInt(from.substring(3, 5), 10)
      const toHour = parseInt(to.substring(0, 2), 10)
      const toMinute = parseInt(to.substring(3, 5), 10)

      let ans = toHour * 60 + toMinute - fromHour * 60 - fromMinute
      if (ans < 0) {
        ans = ans + 24 * 60
      }
      return this.formatDispMinute(ans)
    },
    sumTimes (times) {
      let sum = 0
      for (let time of times) {
        if (time.length === 4) time = '0' + time
        if (time.length !== 5) continue
        const hour = parseInt(time.substring(0, 2), 10)
        const minute = parseInt(time.substring(3, 5), 10)

        sum = sum + hour * 60 + minute
      }
      return this.formatDispMinute(sum)
    },
    isTime (time) {
      if (!time || time.length === 0) return true
      let isTime = false
      if (time && (time.length === 3 || time.length === 4 || time.length === 5)) {
        const index = time.indexOf(':')
        if (index === -1) {
          const hh = time.substring(0, time.length - 2)
          const mm = time.substring(time.length - 2)
          time = hh + ':' + mm
        }
        const hhmm = time.split(':')
        if (hhmm.length === 2) {
          const hh = hhmm[0]
          const mm = hhmm[1]
          if (!isNaN(hh) && !isNaN(mm)) {
            const hour = parseInt(hh, 10)
            const minute = parseInt(mm, 10)
            if (hour >= 0 && hour <= 47 && minute >= 0 && minute <= 59) {
              isTime = true
            }
          }
        }
      }
      return isTime
    },
    convertHhmmss2Hhmm (time) {
      if (!time || time.length < 5) return time
      const hh = parseInt(time.substring(0, 2), 10)
      const mm = time.substring(3, 5)
      return hh + ':' + mm
    },
    convertToTimeFormat (time, max) {
      if (!time || isNaN(time)) {
        return time
      }
      return time < max ? `${time}:00` : time
    },
    formatTime (time) {
      if (!time || !this.isTime(time)) {
        return time
      }
      const index = time.indexOf(':')
      if (index === -1) {
        const hh = time.substring(0, time.length - 2)
        const mm = time.substring(time.length - 2)
        time = hh + ':' + mm
      }
      return ('0' + time).slice(-5)
    },
    compareTime (time1, time2) {
      const hhmm1 = this.formatTime(time1).split(':')
      const hhmm2 = this.formatTime(time2).split(':')
      const minute1 = parseInt(hhmm1[0], 10) * 60 + parseInt(hhmm1[1], 10)
      const minute2 = parseInt(hhmm2[0], 10) * 60 + parseInt(hhmm2[1], 10)
      if (minute1 > minute2) return 1
      if (minute1 < minute2) return -1
      if (minute1 === minute2) return 0
    },
    compareDate (date1, date2) {
      if (!date1 || !date2) return 0
      const ymd1 = date1.split('/')
      const ymd2 = date2.split('/')
      const days1 = parseInt(ymd1[0], 10) * 10000 + parseInt(ymd1[1], 10) * 100 + parseInt(ymd1[2], 10)
      const days2 = parseInt(ymd2[0], 10) * 10000 + parseInt(ymd2[1], 10) * 100 + parseInt(ymd2[2], 10)
      if (days1 > days2) return 1
      if (days1 < days2) return -1
      if (days1 === days2) return 0
    },
    dateAdd (strDate, days) {
      if (!strDate) return strDate
      const ymd = strDate.split('/')
      const date = new Date(parseInt(ymd[0], 10), parseInt(ymd[1], 10) - 1, parseInt(ymd[2], 10))
      date.setDate(date.getDate() + days)
      return moment(date).format('YYYY/MM/DD')
    },
    to24Time (time) {
      if (!time) return time
      const hhmm = this.formatTime(time).split(':')
      let minute = parseInt(hhmm[0], 10) * 60 + parseInt(hhmm[1], 10)
      if (minute >= 24 * 60) {
        minute -= 24 * 60
      }
      return this.formatTime(this.formatDispMinute(minute))
    },
    to48Time (time, nextDay) {
      const hhmm = this.formatTime(time).split(':')
      let minute = parseInt(hhmm[0], 10) * 60 + parseInt(hhmm[1], 10)
      if (nextDay) {
        minute += 24 * 60
      }
      return this.formatTime(this.formatDispMinute(minute))
    },
    canShow (path) {
      let isExist = false
      for (let ss of this.$store.state.ssList) {
        if (ss.path === path) {
          isExist = true
          break
        }
      }
      return isExist
    },
    getRadioName (radio) {
      if (radio && radio.items) {
        for (const item of radio.items) {
          if (radio.value === item.value) {
            return item.label
          }
        }
      }
      return ''
    },
    getSelectName (code, options) {
      if (options) {
        for (const option of options) {
          if (String(code) === option.value || code === option.value) {
            return option.label
          }
        }
      }
      return ''
    },
    getLabels (commonArea) {
      let labels = {}
      for (let category of Object.entries(commonArea)) {
        if (category[1] !== null && typeof category[1] === 'object') {
          for (let entry of Object.entries(category[1])) {
            if (entry[1] !== null && typeof entry[1] === 'string') {
              let label = entry[1].split(',')
              let key = entry[0].substring(0, 1).toLowerCase() + entry[0].substring(1)
              labels[key] = label[0].trim()
              if (label.length > 1) {
                labels[key + '_ryaku'] = label[1].trim()
              }
            } else if (entry[1] !== null && Array.isArray(entry[1])) {
            } else {
              let key = entry[0]
              labels[key] = entry[1]
            }
          }
        }
      }
      this.setVueLanguage(labels)
      return labels
    },
    getLabel (param) {
      if (param) {
        return param.split(',')[0].trim()
      }
      return param
    },
    getLabelRyaku (param) {
      return param.split(',')[1].trim()
    },
    // 配列を連想配列に変換
    convertAssociative (key, values) {
      let resultList = []
      for (let value of values) {
        let tmpList = { [key]: value }
        resultList.push(tmpList)
      }
      return resultList
    },
    // 連想配列でnullをブランクに変換
    convertNullToBlank (list) {
      for (let key in list) {
        if (list[key] == null) {
          list[key] = ''
        }
      }
    },
    nullToBlank (obj) {
      for (const element of Object.entries(obj)) {
        if (element[1] === null || Number.isNaN(element[1])) {
          obj[element[0]] = ''
        }
      }
      return obj
    },
    emptyToBlank (str) {
      if (str === undefined || str === null) {
        str = ''
      }
      return str
    },
    // label,ryakuをlabel変換
    getLabelList (orgList) {
      const labelList = []
      if (orgList) {
        for (const element of orgList) {
          if (element.label === undefined || element.value === undefined) {
            continue
          }
          const label = {
            label: this.getLabel(element.label),
            value: String(element.value)
          }
          if (element.menu_code) {
            label.menu_code = element.menu_code
          }
          labelList.push(label)
        }
      }
      return labelList
    },
    // label,ryakuをryaku変換
    getLabelRyakuList (orgList) {
      const labelRyakuList = []
      if (orgList) {
        for (const element of orgList) {
          const labelRyaku = {
            label: this.getLabelRyaku(element.label),
            value: String(element.value)
          }
          labelRyakuList.push(labelRyaku)
        }
      }
      return labelRyakuList
    },
    // label,ryakuをlabel変換
    getComboData (orgComboData, resComboData) {
      const comboData = {}
      if (orgComboData) {
        for (const element of Object.entries(orgComboData)) {
          if (Array.isArray(element[1])) {
            comboData[element[0]] = this.getLabelList(element[1])
          } else if (element[1] === null) {
            comboData[element[0]] = []
          } else {
            for (const element2 of Object.entries(element[1])) {
              if (Array.isArray(element2[1])) {
                comboData[element2[0]] = this.getLabelList(element2[1])
              }
            }
          }
        }
      }
      if (resComboData) {
        for (const element of Object.entries(resComboData)) {
          if (Array.isArray(element[1])) {
            comboData[element[0]] = this.getLabelList(element[1])
          } else if (element[1] === null) {
            comboData[element[0]] = []
          } else {
            for (const element2 of Object.entries(element[1])) {
              if (Array.isArray(element2[1])) {
                comboData[element2[0]] = this.getLabelList(element2[1])
              }
            }
          }
        }
      }
      return comboData
    },
    // APIから受け取ったリストをプルダウン用に変換する
    convertCodeList (list) {
      let resultList = [{label: '', value: ''}]
      if (list == null) {
        return resultList
      }
      for (const nameAndCode of list) {
        const data = {
          label: nameAndCode.name,
          value: nameAndCode.code
        }
        resultList.push(data)
      }
      return resultList
    },
    // チェックボックスの値を配列に変換する
    toSelectedArray (checkbox) {
      if (!checkbox) return checkbox
      if (!checkbox.items) return checkbox
      const array = []
      for (const item of checkbox.items) {
        if (item.value) {
          array.push(parseInt(item.id, 10))
        }
      }
      return array
    },
    breakLabel (label, rowCount) {
      let newLabel = ''
      if (label) {
        const lengthPerRow = label.length / rowCount
        for (let i = 0; i < label.length; i += lengthPerRow) {
          if (label.length < i + lengthPerRow) {
            newLabel += label.substring(i)
          } else {
            newLabel += label.substring(i, i + lengthPerRow) + '<br>'
          }
        }
      }
      return newLabel
    },
    getBytes (str) {
      let length = 0
      if (str) {
        if (String(str).indexOf(',') > -1) {
          str = str.split(',')[0]
        }
        for (let i = 0; i < str.length; i++) {
          const c = str.charCodeAt(i)
          if ((c >= 0x0 && c < 0x81) || (c === 0xf8f0) || (c >= 0xff61 && c < 0xffa0) || (c >= 0xf8f1 && c < 0xf8f4)) {
            length += 1
          } else {
            length += 2
          }
        }
      }
      return length
    },
    getColumnWidth (rows, columnNameValue, columnName1, columnName2) {
      let maxBytes = this.getBytes(columnNameValue)
      if (rows) {
        for (const row of rows) {
          if (row && row[columnName1]) {
            const bytes = this.getBytes(String(row[columnName1]))
            if (bytes > maxBytes) {
              maxBytes = bytes
            }
          }
          if (row && row[columnName2]) {
            const bytes = this.getBytes(String(row[columnName2]))
            if (bytes > maxBytes) {
              maxBytes = bytes
            }
          }
        }
      }
      if (this.$route.path === '/03/menu03Screen023/search' && maxBytes < 20) {
        maxBytes = 20
      }
      return maxBytes > 30 ? maxBytes * 7 + 20 : maxBytes * 8 + 20
    },
    getColumnWidthStrong (rows, columnNameValue, columnName1, columnName2, columnName3 = '') {
      let maxBytes = this.getBytes(columnNameValue)
      if (rows) {
        for (const row of rows) {
          if (row[columnName1]) {
            const bytes = this.getBytes(row[columnName1])
            if (bytes > maxBytes) {
              maxBytes = bytes
            }
          }
          if (row[columnName2]) {
            const bytes = this.getBytes(row[columnName2])
            if (bytes > maxBytes) {
              maxBytes = bytes
            }
          }
          if (row[columnName3]) {
            const bytes = this.getBytes(row[columnName3])
            if (bytes > maxBytes) {
              maxBytes = bytes
            }
          }
        }
      }
      if (this.$route.path === '/03/menu03Screen023/search' && columnName1 === 'employee_classification_name' && maxBytes < 20) {
        maxBytes = 20
      }
      if (this.$route.path === '/08/menu08Screen002') {
        maxBytes = 20
      }
      if (this.$route.path === '/03/menu03Screen004/search' && columnName1 === 'work_schedule_code' && maxBytes > 20) {
        maxBytes = 20
      }
      return maxBytes > 60 ? maxBytes * 9 + 20 : maxBytes > 30 ? maxBytes * 8 + 21 : maxBytes * 9 + 20
    },
    getColumnWidthStrongEnum (rows, columnNameValue, columnEnumList, columnName1) {
      let maxBytes = this.getBytes(columnNameValue)
      if (rows) {
        for (const row of rows) {
          if (row[columnName1]) {
            const bytes = this.getBytes(this.getLabel(this.getSelectName(row[columnName1], columnEnumList)))
            if (bytes > maxBytes) {
              maxBytes = bytes
            }
          }
        }
      }
      return maxBytes > 60 ? maxBytes * 9 + 20 : maxBytes > 30 ? maxBytes * 8 + 21 : maxBytes * 9 + 20
    },
    getButtonColumnWidth (buttonNames) {
      let bytes = 0
      for (const buttonName of buttonNames) {
        bytes += this.getBytes(buttonName)
      }
      return bytes * 9 + 50 * buttonNames.length
    },
    getSelectLengthClass (list) {
      let maxBytes = 0
      if (list) {
        for (const row of list) {
          if (row.label) {
            const bytes = this.getBytes(row.label)
            if (bytes > maxBytes) {
              maxBytes = bytes
            }
          }
        }
      }
      if (maxBytes < 10) maxBytes = 10
      return `length${maxBytes}`
    },
    /**
     * マッピング結果を返却する。
     * @param parentValue 親の値
     * @param mappingList マッピングの配列
     * @param childList 子の連想配列
     * @returns resultList マッピング結果の連想配列
     */
    getMappingList (parentValue, mappingList, childList) {
      var resultList = []
      // マッピングの配列にリストがなければ処理を終了する。
      if (!mappingList[parentValue]) {
        return resultList
      }
      var targetList = mappingList[parentValue]
      // 子の連想配列の値とマッピングの配列で一致するものをマッピング結果に追加する。
      for (let child of childList) {
        if (targetList.includes(child.value)) {
          resultList.push(child)
        }
      }
      return resultList
    },
    getSidebarLinks (self, systemCompanyFlg, menuList, employeeDashboardList, dbList) {
      const sidebarLinks = []
      const ssList = []
      for (var menu of menuList) {
        const sidebarLink = {
          name: '',
          icon: '',
          collapsed: true,
          children: []
        }
        sidebarLink.name = self.getLabel(menu.menu_name)
        sidebarLink.icon = menu.icon_name
        for (var screen of menu.screen_list) {
          const path1 = screen.vue_path.substring(0, screen.vue_path.indexOf('Screen')).replace('Menu', '')
          const path2 = screen.vue_path.replace('Menu', 'menu').replace('.vue', '')
          const child = {
            name: '',
            icon: '',
            path: '',
            code: ''
          }
          child.name = self.getLabelRyaku(screen.screen_name)
          child.icon = screen.icon_name
          child.path = `/${path1}/${path2}`
          child.code = screen.screen_code
          if (screen.screen_type === 1) {
            sidebarLink.children.push(child)
          } else if (screen.screen_type === 2) {
            ssList.push(child)
          }
          if (menu.menu_code === '04') {
            ssList.push(child)
          }
        }
        if (sidebarLink.children.length > 0) {
          sidebarLinks.push(sidebarLink)
        }
      }
      const dashboardList = [...dbList]
      dashboardList.sort((a, b) => parseInt(a.screen_code.slice(-3)) - parseInt(b.screen_code.slice(-3)))
      for (const dashboard of dashboardList) {
        let isExist = false
        for (const employeeDashboard of employeeDashboardList) {
          if (dashboard.screen_code === employeeDashboard.screen_code) {
            isExist = true
            dashboard.selected = true
            dashboard.sort_number = employeeDashboard.sort_number
            dashboard.screen_code = dashboard.screen_code
            dashboard.screen_name = dashboard.screen_name
            break
          }
        }
        if (!isExist) {
          dashboard.selected = false
          dashboard.sort_number = 0
          dashboard.screen_code = dashboard.screen_code
          dashboard.screen_name = dashboard.screen_name
        }
      }
      self.$store.commit('setDashboardList', dashboardList)
      self.$store.commit('setDbList', dbList)
      self.$store.commit('setSsList', ssList)
      self.$sidebar.sidebarLinks = sidebarLinks
      return sidebarLinks
    },
    /**
     * ダミーデータを設定する。
     * @param data 配列
     * @param currentPage 現在のページ
     * @param perPage 1ページ数
     * @param beforeCurrentPage 前の現在のページ
     * @param beforePerPage 前の1ページ数
     * @returns ダミーデータで埋めた配列
     */
    getDummyData (data, currentPage, perPage, beforeCurrentPage, beforePerPage) {
      let afterBase = 0
      // ページ送り時は対象のページ、ページ件数変更時は最初を設定
      if (perPage === beforePerPage) {
        afterBase = (currentPage - 1)
      }
      // 前の1ページ数分、処理を繰り返す
      for (let i = 0; i < beforePerPage; i++) {
        // 前のページの情報を今のページに代入
        let before = (beforeCurrentPage - 1) * beforePerPage + i
        let after = afterBase * beforePerPage + i
        data[after] = data[before]
      }
      return data
    },
    /**
     * ページリストを返却する。
     * @param data 配列
     * @param currentPage 現在のページ
     * @param perPage 1ページ数
     * @param totalCount 最大件数
     * @returns 空配列で埋めた配列
     */
    getPageList (data, currentPage, perPage, totalCount) {
      // 表示するページ以外を空配列で埋める
      // 先頭の場合、処理なし
      if (currentPage !== 1) {
        let beforeCount = (currentPage - 1) * perPage
        data = Array(beforeCount).fill({}).concat(data)
      }
      const affterCount = totalCount - data.length
      // 末尾の場合、処理なし
      if (affterCount > 0) {
        data = data.concat(Array(totalCount - data.length).fill({}))
      }
      return data
    },
    getContentType (extension) {
      switch (extension) {
        case 'jpg':
          return 'image/jpg'
        case 'png':
          return 'image/png'
        case 'pdf':
          return 'application/pdf'
      }
    },
    openReportPage (path = '/#/08/menu08Screen007') {
      const report = window.open(path, '_report')
      report.postMessage('init')
    },
    setVueLanguage (labels) {
      const vueLanguage = {
        message: {},
        label: {},
        button: {},
        placeholder: {},
        help: {},
        movie: {}
      }
      for (let label of Object.entries(labels)) {
        if (label[0].substring(0, 3) !== 'vue') continue
        const prefix = label[0].substring(0, 8)
        const value = label[1].split(',')[0]
        switch (prefix) {
          case 'vueMessa':
            vueLanguage.message[label[0].replace('vueMessage_', '')] = value
            break
          case 'vueLabel':
            vueLanguage.label[label[0].replace('vueLabel_', '')] = value
            break
          case 'vueButto':
            vueLanguage.button[label[0].replace('vueButton_', '')] = value
            break
          case 'vuePlace':
            vueLanguage.placeholder[label[0].replace('vuePlaceholder_', '')] = value
            break
          case 'vueHelp_':
            vueLanguage.help[label[0].replace('vueHelp_', '')] = value
            break
          case 'vueMovie':
            vueLanguage.movie[label[0].replace('vueMovie_', '')] = value
            break
        }
      }
      if (this.$i18n) {
        const messages = this.$i18n.messages[this.$i18n.locale]
        if (messages) {
          messages.message = {...messages.message, ...vueLanguage.message}
          messages.label = {...messages.label, ...vueLanguage.label}
          messages.button = {...messages.button, ...vueLanguage.button}
          messages.placeholder = {...messages.placeholder, ...vueLanguage.placeholder}
          messages.help = {...messages.help, ...vueLanguage.help}
          messages.movie = {...messages.movie, ...vueLanguage.movie}
          messages.placeholder.town = messages.placeholder.city
          messages.label.unit_meter = messages.placeholder.meter ? messages.placeholder.meter : messages.label.unit_meter
          this.$i18n.setLocaleMessage(this.$i18n.locale, messages)
        }
      }
    },
    isAllZero (str) {
      for (const character of str.split('')) {
        if (character !== '0') {
          return false
        }
      }
      return true
    },
    // 先頭の0を削除
    zeroSuppress (str) {
      // 0時の場合を考慮
      if (str.startsWith('0:')) {
        return str
      } else if (str.startsWith('00:')) {
        return str.replace(/^0/, '')
      }
      return str.replace(/^0+/, '')
    },
    replay () {
      this.$tours['myTour'].start()
    },
    round (value, base) {
      return Math.round(value * base) / base
    },
    getColoredData (rows) {
      const tableData = []
      for (const row of rows) {
        switch (row.application_status) {
          case 1:
            row.color = 'rgb(237, 128, 119)'
            break
          case 2:
            row.color = 'rgb(134, 140, 183)'
            break
          case 3:
            row.color = 'rgb(224, 123, 154)'
            break
          case 4:
            row.color = 'rgb(244, 40, 88)'
            break
          case 5:
            row.color = 'rgb(59, 157, 189)'
            break
          case 6:
            row.color = 'rgb(234, 44, 0)'
            break
          case 7:
            row.color = 'rgb(161, 175, 47)'
            break
        }
        console.log(row.color)
        tableData.push(row)
      }
      return tableData
    }
  },
  data () {
    return {
      modes: {
        add: 1,
        edit: 2,
        view: 3,
        delete: 4
      },
      disableApplicationForms: [
        '0000000009',
        '0000000008',
        '0000000011',
        '0000000014'
      ]
    }
  }
}
