<template>
  <div>
    <LoginHeader/>

    <div class="wrapper wrapper-full-page">
      <div
        class="full-page login-page"
      >
        <!--   you can change the color of the filter page using: data-color="blue | azure | green | orange | red | purple" -->
        <div class="content">
          <div class="container">
            <div class="row">
              <div class="col-md-6 col-sm-8 col-md-offset-3 col-sm-offset-2">
                <div style="margin-top: -80px; margin-bottom: 20px;">
                  <img src="static/img/logo_c2.png" style="width: 100%;">
                </div>
              </div>
              <div class="col-md-4 col-sm-6 col-md-offset-4 col-sm-offset-3">
                {{ this.messageText }}
                <br/>
                <br/>
                <br/>
                <a href="#" style="color: #317F88" @click.prevent="adminLogin">{{$t('button.to_admin_login')}}</a>
              </div>
            </div>
          </div>
        </div>

        <footer class="footer footer-transparent">
          <div class="container">
            <div class="buttons" style="text-align: center;">
              <a class="button" href="/static/felica/Artemis Collier.zip">{{$t('label.felica_download')}}</a>
              <a class="button" href="/#/login">{{$t('label.artemis_login')}}</a>
              <a class="button" href="/#/qrcode/login">{{$t('label.qrcode_login')}}</a>
              &nbsp;
              <a href="https://apps.apple.com/app/id1625521109" target="_blank"><img class="button_appstore" src="/static/img/app_store.png" /></a>
              &nbsp;&nbsp;&nbsp;
              <a href="https://play.google.com/store/apps/details?id=jp.co.pocketsoft.flutter.artemis" target="_blank"><img class="button_appstore" src="/static/img/google_play.png" /></a>
            </div>
            <div style="text-align: center;">
              <div style="display: inline-block;">
                <div class="copyright">
                  &copy; 2020-2024 PocketSoft Co., Ltd.
                </div>
              </div>
              <div style="display: inline-block;">
                <a style="color: #666" href="/#/notation-on-law">{{$t('label.notation_on_law')}}</a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
  import LoginHeader from '@/components/Dashboard/Views/Login/LoginHeader'
  import mixin from '@/lib/mixin'
  import swal from 'sweetalert2'

  export default {
    mixins: [mixin],
    components: {
      LoginHeader
    },
    data () {
      return {
        messageText: undefined
      }
    },
    methods: {
      adminLogin () {
        window.location.href = '/#/admin/login'
      },
      complete () {
        const params = {
          orgEmail: this.$store.state.userLoginUser.accessMailAddress,
          newEmail: this.$store.state.userLoginUser.newMailAddress
        }
        this.$axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeUpdateEmail', params)
        .then(res => {
          let data = {
            token: this.$route.query.token
          }
          this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/update/change_access_mail_address`, data)
          .then(data => {
            console.log(res.data)
            this.messageText = data.message.text
          }).catch(error => {
            console.error(error)
          })
        })
        .catch(error => {
          console.log(error)
          swal({
            text: this.$t('message.unexpected_error'),
            type: 'error',
            confirmButtonClass: 'btn btn-warning btn-sm btn-default',
            buttonsStyling: false,
            allowOutsideClick: false
          })
        })
      }
    },
    created () {
      this.complete()
    }
  }

</script>
<style scoped>
  .card {
    background: #fff !important;
  }
  .card input {
    background: #eee !important;
  }
  .navbar-nav a {
    color: black !important;
  }
  .navbar-brand {
    color: black !important;
  }
  .copyright {
    color: black !important;
  }
</style>
