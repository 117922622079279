export default {
  ja: {
    message: {
      stamping_radius_required: '打刻半径は必須項目です。入力をお願いします。',
      attendance_application_change_warning: '出勤簿申請を管理しないに変更すると以下の申請も管理しないに変更されます。よろしいですか？<br>・深夜申請<br>・法定休日出勤申請<br>・残業申請<br>・遅刻申請<br>・早退申請',
      latlng_required: '地図上のピンは必須項目です。事業所の所在地にピンを立ててください。',
      duplicate_work_schedule_at: '{date}の勤務体系が重複しています。異なる勤務体系を選択してください。',
      group_changed: '部署または利用権限が変更されたため、再度ログインしてください。',
      is_individual_or_common_route_required: '{item1}、または{item2}が未入力の行があります。入力するか削除にチェックを入れてください。',
      is_individual_route_required: '{item1}が未入力の行があります。入力するか削除にチェックを入れてください。',
      is_common_route_required: '{item2}が未入力の行があります。入力するか削除にチェックを入れてください。',
      applicant_not_found: '対象者が選択されていません。',
      applicant_not_found_description: '対象者を選択してから操作してください。',
      employee_not_found: '従業員が見つかりませんでした。',
      employee_not_found_description: '従業員番号または権限を確認してください。',
      is_duplicate_approver_selected: '同じ部署の同じ従業員が選択されています。別の部署または別の従業員を選択してください。',
      invalid_parental_leave_hours: '育児休暇取得時間が正しくありません。',
      invalid_paid_holiday_hours: '有給休暇取得時間が正しくありません。',
      closing_date_is_not_found: '締め日が作成されていません',
      transformation_year_term_is_out_of_range: '期間が範囲外です。1か月を超えて1年以内の期間を選択してください。',
      transformation_month_term_is_out_of_range: '期間が範囲外です。1か月以内の期間を選択してください。',
      is_set_all_days: '入社年月日の設定が不足しているか重複しています。週の労働日ごとに１年分の入社年月日を設定してください。',
      is_time_error: '0:00～720:00 で入力して下さい。',
      max_time_value: '{max}以下で入力してください。',
      unavailable_choice: '利用不可にするには、明細レイアウトから給与項目を外してください。',
      cannot_do_cancellation: '会社が存在します。サービスを解約する場合、事前に会社を削除してください。',
      no_change_mail_address: '変更前とは異なるメールアドレスを入力してください。',
      download_list_confirmation: '{process}を開始しました。ダウンロードリストに移動しますか？',
      upload_list_confirmation: '{process}を開始しました。アップロードリストに移動しますか？',
      is_start_end_time: '終業時間は始業時間より後の時間を入力してください',
      shift_publish_confirmation_title: 'シフトを公開（出勤簿に反映）します。\n公開してよろしいですか？',
      shift_publish_confirmation_html: '<small>※公開後のシフトは、この画面で「非公開」にすることで修正できます。</small>',
      shift_fix_confirmation_title: 'すべてのシフトを確定済みに変更します。よろしいですか？',
      shift_published: 'シフトが設定されている日には行追加できません。',
      is_start_end_time_work_schedule: '勤務体系を選択した場合は始業・終業時間も入力してください。',
      is_work_schedule_start_end_time: 'シフトを設定する場合は勤務体系も入力してください。',
      application_cancel_check: '勤務体系を変更すると残業、遅刻、早退、休日出勤、深夜残業の申請書は取下げされます。<br>よろしいですか？',
      credit_card_is_valid: 'クレジットカード番号が更新されました。',
      is_item_number_for_row_rounding: '{row}行目：{employee}の{item}は1以上の数値を入力してください。',
      is_item_required_for_row_rounding: '{row}行目：{employee}の{item}は必須です。',
      cannot_delete_start_minutes: '退勤済みのため、出勤時刻のみを削除することはできません。',
      inconsistent_start_end_time: '{date}の入力に不備があります。出勤時間と退勤時間は両方の時間を正確に入力してください。',
      no_group_term_exist: '所属がない期間が存在します。',
      cannot_add_shift_employee: '選択された従業員は既に追加されています。',
      shift_employee_not_found: '従業員が選択されていません。',
      range_break_time_error: '{row}行目の休憩時間は拘束時間以下の時間を設定してください。',
      no_shift_employee_title: '行追加した従業員の中に、まだシフトが一つも登録されていない従業員がいます。更新してもよろしいですか？',
      no_shift_employee_content: 'シフトが登録されていない従業員の行は削除されます。',
      confirm_approve_all: '選択された申請を一括承認します。よろしいですか？',
      require_update: '内容が修正されています。更新ボタンを押してください。',
      require_calc: 'データが変更されています。計算ボタンを押してください。<br>',
      calc_before_update: '計算ボタンを押してから実行してください。',
      no_employees_selected: '一括登録する従業員を選択してください。',
      new_resident_tax_setting_error: '都道府県、市区町村を選択してください。',
      layout_required: 'レイアウトを選択してください。',
      file_size_over: 'ファイルサイズが超過しています。{size}KB以下のファイルを選択してください。',
      work_schedule_code_changed: '勤務体系が変更されています。先に出勤簿を更新してください。',
      is_working_item_required: '勤怠項目は一つ以上有効にしてください。'
    },
    label: {
      unit_meter: 'm',
      stamping_radius_flg: '打刻半径を設定します',
      is_notice: 'お知らせを送ります',
      number_of_employees_in_use: '利用中の従業員数',
      change_ok_flg: '用語',
      imprint_correction_break_time: '休憩時刻',
      before_imprint_correction_break_time: '変更前休憩時刻',
      reflect_not_closed_months_description: '（締めた月には反映されません。）',
      employee_lunch_target_date: '天引き予定年月',
      help_text_office_reference_number: '算定基礎届（月額変更届）に出力されます。',
      picture: '写真',
      do: 'する',
      not_do: 'しない',
      calendar: 'カレンダー',
      more_than_or_equal_to: '以上',
      less_than_or_equal_to: '以下',
      hint: 'ヒント',
      industry: '業種',
      closed_at: '日締めで',
      next_month: '翌月',
      imprint_correction: '打刻補正',
      label_included_fixed_wages_social_security: '社会保険料を決定する上での固定賃金区分',
      day_before: '日前',
      quick_tour: 'クイックツアー',
      next_step: '次の必須項目へ',
      prev_step: '前へ',
      finish_step: '完了',
      change_work_schedule: '勤務体系一括変更',
      change_ground: '事由一括変更',
      include_no_stamp: '未打刻も含む',
      all: 'すべて',
      transformation_working_time: '変形労働',
      flextime_full_two_day_weekend_system: '完全週休二日制におけるフレックスタイムの適用有無',
      number_of_working_hours_on_the_xxth: '1か月単位の法定労働時間の総枠',
      number_of_working_hours_on_the_28th: '28日',
      number_of_working_hours_on_the_29th: '29日',
      number_of_working_hours_on_the_30th: '30日',
      number_of_working_hours_on_the_31th: '31日',
      stamping_type: '　区分　',
      typing_stamping_time: '打刻時間',
      status_kana: 'ステータス',
      aggregate_date: '最終集計日：',
      agreement_parties_select_method: '協定当事者の選択方法',
      management_parties_select_method: '使用者の選択方法',
      select_method1: '現在割り当て中の職名と従業員から選択',
      select_method2: '全ての職名と従業員から選択',
      open_employee_list: '出勤簿が未承認の従業員',
      salary_open_employee_list: '給与明細が未作成の従業員',
      change_mail_address: 'メールアドレスの変更',
      seven_days: 'から1週間',
      type_of_day_summary1: '労働日 {total_working_day}日（出勤日：{total_attendance_day}日、有給休暇奨励日：{total_unknown_day}日、',
      type_of_day_summary2: '有給休暇の計画的付与：{total_unknown2_day}日、使用者の責に帰す休業日：{total_unknown3_day}日）',
      type_of_day_summary3: '休日　 {total_holiday}日（所定休日：{total_job_holiday}日、法定休日：{total_legal_holiday}日、',
      type_of_day_summary4: '国民の祝日{total_national_holiday}日、年末年始：{total_new_year_holiday}日、夏季休暇：{total_summer_holiday}日）',
      deputy_apply: '代理申請',
      attendance_record_layout: 'PDFレイアウト',
      different_amount: '差異',
      paid_history: '＋　支給/失効履歴',
      tooltip_horizontal_scroll: 'スクロールを左右にスキップします。',
      tooltip_clear: '全ての行の人数を0にします。',
      user_login_user_info: '[ログイン情報]　メールアドレス：{mailAddress}　氏名：{userName}',
      menu: 'メニュー',
      user_logo_title: 'Artemis Collier / My Collier',
      use_paid_holiday: '有給休暇申請を利用',
      use_parental_leave: '育児休暇申請を利用',
      use_leave_job: '休職申請を利用',
      unit_price_description: '1時間当たりの賃金単価です。<br>基礎賃金を1か月の平均所定労働時間で割った値が表示されます。<br><br>次の計算式で計算されます。<br>（給与情報タブの固定項目[基本給]の合計）÷（一年間の出勤日数 / 12か月） ÷　所属している事業所の所定労働時間<br>※一年間の出勤日数は365日からカレンダーの休日日数を引いた数です。',
      salary_edit_description: '変動項目の値を変更します。（「計算」ボタンをクリックすると変更後の金額で再計算します。）',
      salary_edit_cancel_description: '変更した値を戻して再計算します。',
      residents_card3: '(診断書等)',
      payment_notation: 'キャンペーンコードをお持ちの方は入力してください。お持ちでない方はそのまま支払い画面にお進みください。',
      campaign_code: 'キャンペーンコード',
      user: '利用者',
      company: '会社',
      working_minutes: '労働時間',
      pattern: 'パターン',
      shift_holiday: '休み',
      send_message_individually: '個別にメッセージで送信',
      send_whole_shift: '全体のシフト表を送信',
      send_shift: 'シフト送信',
      shift_publish_text: '編集するにはシフト調整画面で非公開にする必要があります。',
      no_alert: '通知はありません',
      shift_pattern_setting: 'シフトパターン入力',
      shift_pattern: 'シフトパターン',
      all_date_range: '全期間',
      specified_date_range: '指定した期間',
      apply_date_range: '適用期間',
      date_from_description: 'から',
      date_to_description: 'まで',
      end_of_month: '月末',
      search_three_years: '直近3年より前の締日も含めて検索',
      rounding_type_0: '会社情報の丸め時間設定に従う',
      shift_job_holiday: '所定',
      shift_legal_holiday: '法定',
      shift_number_of_employees_setting: 'シフト計画人数設定',
      shift_number_of_employees: 'シフト計画人数',
      check_all: '全選択/解除',
      legal_holiday: '法定休日',
      industry_code_description: 'ここで設定する業種とは、その地域において特定の業種に定められている最低労働賃金を確認する為のものです。その為、もし、お客様の業種が表示されていない場合は選択は不要です。',
      working_time: '労働時間の範囲',
      work_ground: '出勤日へ変更して打刻',
      ground: '事由',
      set_by_time: '時間で入力',
      set_by_ratio: '割合で入力',
      set_by_date: '日数で入力',
      set_by_times: '回数で入力',
      range_break_minutes: '労働時間のしきい値',
      range_break_minutes_description: 'を超える場合の休憩時間',
      fixed_break_time_label: '固定休憩',
      range_break_time_label: '時間指定休憩',
      threshold_break_time_label: '拘束時間指定休憩',
      total_resident_tax: '特別徴収税額',
      prefectures: '都道府県',
      remaining_days: '残日数',
      consumed_days: '取得日数',
      consumed_hours: '取得時間数',
      not_set_employee_list: '未設定従業員一覧',
      resident_tax_employee: '住民税の対象者',
      csv_formula: '計算式設定',
      csv_enabled: '有効',
      csv_template_draggable: '項目の並び順はドラッグ&ドロップで変更してください。'
    },
    button: {
      put_pin: '住所を元に地図上のピンを変更します。',
      calendar: '年間カレンダー',
      read: '既読にする',
      change_work_schedule: '勤務体系一括変更',
      change_ground: '事由一括変更',
      modify: '変更',
      set_standard_values: '労働基準法に準拠します。',
      show_working_day_summary: '日集計',
      show_working_day: '集計前',
      timecard_history: '打刻履歴',
      reaggregate: '再集計',
      vertical: '縦向き',
      horizontal: '横向き',
      reload2: '再読み込み',
      today: '当日',
      to_admin_login: '管理者ログインへ',
      scroll_left: '<<',
      scroll_right: '>>',
      download_format: '一括登録用Excel作成',
      delete_company_cancellation: '会社削除／サービス解約',
      move_to: 'を開く',
      upload_all: '一括アップロード',
      employee: '従業員',
      apply_shift_pattern: '反映',
      shift_send: 'シフト送信',
      imprint_correction: '打刻補正申請',
      approve_all: '一括承認',
      add_all: '一括登録',
      copy_previous_year: '前年度の情報をコピー',
      copy_july: '7月以降をコピー',
      save: '保存',
      expand_table: '表を拡大',
      shrink_table: '表を縮小',
      copy: 'コピーして作成'
    },
    placeholder: {
      stamping_radius: '100',
      formula: '[単価] / [月の平均労働時間] * 1.25 * [法定外労働時間]',
      job_holiday_comment: '毎週土曜・日曜',
      office_reference_number: '123456',
      paid_holiday_content: '会社への連絡事項がある場合にご利用ください。有給休暇の申請に理由は不要です。',
      work_type_name: '会議',
      report_code: '08-002-001',
      leave_job_content: '療養が必要と診断されたため。',
      supplement: '休職終了日については要相談',
      campaign_code: 'ABCDEF',
      designated_number: '0000000000',
      addressee_number: '0000000000',
      resident_municipality_code: '000000',
      beneficiary_number: '0000000000',
      resident_tax_jun: '0',
      resident_tax_jly: '0',
      resident_tax_aug: '0',
      resident_tax_sep: '0',
      resident_tax_oct: '0',
      resident_tax_nov: '0',
      resident_tax_dec: '0',
      resident_tax_jan: '0',
      resident_tax_feb: '0',
      resident_tax_mar: '0',
      resident_tax_apl: '0',
      resident_tax_may: '0',
      remarks_others: '0',
      working_item_name: '勤怠項目'
    },
    help: {
    },
    movie: {
      salary_tran: 'https://www.youtube.com/watch?v=t2lmyIaozDY',
      dashboard: 'https://www.youtube.com/watch?v=O5i1Yr3QK3U&t=6s',
      bonus: 'https://www.youtube.com/watch?v=3r98DiSVX7w&t=24s',
      paid_holiday_payment: 'https://www.youtube.com/watch?v=qSoU_2OdMJE',
      change_password: 'https://www.youtube.com/watch?v=TxrWrjMjLUU',
      search_screen: 'https://www.youtube.com/watch?v=6tG9jjTFdRI&t=1s',
      parlance: 'https://www.youtube.com/watch?v=4uyC9OUweX8&t=1s'
    }
  },
  en: {
    message: {
    },
    label: {
    },
    button: {
    },
    placeholder: {
    },
    help: {
    }
  }
}
