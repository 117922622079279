<template>
  <div class="form-group">
    <ValidationProvider ref="provider" v-slot="{ valid, errors }" :rules="rules" :name="itemLabel1 + '|' + new Date().getTime().toString(16)">
      <div>
        <div class="error-message-wrapper" v-show="!valid">
          <small class="text-danger">
            <!-- エラーメッセージは付加した乱数を削除して表示する。 -->
            {{ getMessage(errors[0]) }}
          </small>
        </div>
      </div>
      <WorkingDataExport
        v-model="value"
        :options="options"
        :rules="rules"
        :item-label1="itemLabel1"
        :item-label2="itemLabel2"
        :item-label3="itemLabel3"
        :item-label4="itemLabel4"
        :disabled="disabled"
        :expand="expand"
        :quotation-mark="quotationMark"
        @expand="$emit('expand')"
      />
    </ValidationProvider>
  </div>
</template>

<script>
  import ElTableDraggable from 'element-ui-el-table-draggable'
  import mixin from '@/lib/mixin'
  import WorkingDataExport from './WorkingDataExport.vue';

  export default {
    mixins: [mixin],
    components: {
      ElTableDraggable,
      WorkingDataExport
    },
    props: {
      value: Array,
      options: Array,
      rules: String,
      itemLabel1: String,
      itemLabel2: String,
      itemLabel3: String,
      itemLabel4: String,
      disabled: Boolean,
      expand: Boolean,
      quotationMark: String
    },
    computed: {
      maxHeight () {
        return this.expand ? window.innerHeight - 250 : window.innerHeight - 680
      }
    },
    data () {
      return {
        tableData: [],
        checkAll: false
      }
    },
    watch: {
      value () {
        this.tableData = this.value
      }
    },
    mounted () {
      this.tableData = this.value
    },
    methods: {
      checkAllChanged () {
        for (const row of this.tableData) {
          row.enabled = this.checkAll
        }
        this.tableData = [...this.tableData]
        console.log(this.tableData)
      },
      getMessage (message) {
        if (message === null || message === undefined) {
          return ''
        } else if (message.indexOf('|') === -1) {
          return message
        } else {
          return message.replace(message.substring(message.indexOf('|'), message.indexOf('|') + 12), '')
        }
      }
    }
  }
</script>

<style scoped>
</style>