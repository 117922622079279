/* eslint-disable camelcase */
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja.json'
import { required, min } from 'vee-validate/dist/rules'

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  beforeCreate () {
    extend('required', {
      ...required,
      message: this.$t('message.field_required')
    })
    extend('required_simple', {
      ...required,
      message: this.$t('message.field_required_simple')
    })
    extend('min', {
      ...min,
      message: this.$t('message.min')
    })
    extend('min_value', {
      validate: (value, { min }) => {
        let numValue = 0
        if (typeof value === 'string') {
          numValue = Number(value.replaceAll(',', ''))
        } else {
          numValue = value
        }
        const numMin = Number(min)
        return numValue >= numMin
      },
      params: ['min'],
      message: this.$t('message.min_value')
    })
    extend('max_value', {
      validate: (value, { max }) => {
        let numValue = 0
        if (typeof value === 'string') {
          numValue = Number(value.replaceAll(',', ''))
        } else {
          numValue = value
        }
        const numMax = Number(max)
        return numValue <= numMax
      },
      params: ['max'],
      message: this.$t('message.max_value')
    })
    extend('max_value_if', {
      params: ['max', 'over3month'],
      validate: (value, { max, over3month }) => {
        console.log(value, max, over3month)
        let numValue = 0
        if (typeof value === 'string') {
          numValue = Number(value.replaceAll(',', ''))
        } else {
          numValue = value
        }
        const numMax = Number(max)
        return over3month !== 'true' || numValue <= numMax
      },
      message: this.$t('message.max_value')
    })
    extend('max_time_value', {
      validate: (value, { max }) => {
        let numValue = parseInt(value.split(':')[0], 10) * 60 + parseInt(value.split(':')[1], 10)
        const numMax = parseInt(max.split(':')[0], 10) * 60 + parseInt(max.split(':')[1], 10)
        return numValue <= numMax
      },
      params: ['max'],
      message: this.$t('message.max_time_value')
    })
    extend('decimal', {
      validate: (value, { decimals = '*', separator = '.' } = {}) => {
        if (value === null || value === undefined || value === '') {
          return {
            valid: false
          }
        }
        if (Number(decimals) === 0) {
          return {
            valid: /^-?\d+$/.test(value)
          }
        }
        const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
        const regex = new RegExp(`^[-+]?\\d+(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)
        return {
          valid: regex.test(value.replaceAll(',', ''))
        }
      },
      message: this.$t('message.decimal_required')
    })
    extend('decimal_simple', {
      validate: (value, { decimals = '*', separator = '.' } = {}) => {
        if (value === null || value === undefined || value === '') {
          return {
            valid: false
          }
        }
        if (Number(decimals) === 0) {
          return {
            valid: /^-?\d+$/.test(value)
          }
        }
        const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`
        const regex = new RegExp(`^[-+]?\\d+(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`)
        return {
          valid: regex.test(value.replaceAll(',', ''))
        }
      },
      message: this.$t('message.decimal_required_simple')
    })
    extend('tel', {
      validate: (value) => {
        if (value === null || value === undefined || value === '') {
          return {
            valid: true
          }
        }
        return {
          valid: /^0\d{1,4}-\d{1,4}-\d{3,4}$/.test(value)
        }
      },
      message: this.$t('message.tel')
    })
    extend('postalCode', {
      validate: (value) => {
        if (value === null || value === undefined || value === '') {
          return {
            valid: true
          }
        }
        return {
          valid: /^\d{3}-\d{4}$/.test(value)
        }
      },
      message: this.$t('message.postalCode')
    })
    extend('check', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        var isChecked = false
        for (let item of value.items) {
          if (item.value) {
            isChecked = true
            break
          }
        }
        return {
          valid: isChecked
        }
      },
      message: this.$t('message.field_required')
    })
    extend('isWorkTime', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        var isTime = false
        // hh:mm形式チェック（整数 + ':' + 59）
        if (value) {
          if (value.match(/^\d{1,3}:[0-5][0-9]$/)) {
            isTime = true
          }
        }
        // 0の場合スルーする
        if (value === 0 || value === '0') {
          isTime = true
        }
        return {
          valid: isTime
        }
      },
      message: this.$t('message.field_work_time')
    })
    extend('isTime', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        var isTime = false
        // hhh:mm形式チェック（720:00まで）
        if (value) {
          console.log(value)
          if (value.match(/^((([0-9]|[0-9][0-9]|[0-6][0-9][0-9]|7[0-1][0-9]):([0-5][0-9]))|720:00)$/)) {
            isTime = true
          }
        }
        return {
          valid: isTime
        }
      },
      message: this.$t('message.is_time_error')
    })
    extend('employeeRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        return {
          valid: !!value[0]
        }
      },
      message: this.$t('message.field_required')
    })
    extend('isStartTime', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        var isStartTime = false
        // 開始時間hh:mm形式チェック（23:59まで）
        if (value[0]) {
          if (value[0].match(/^(([0-9]|[0-1][0-9]|2[0-3]):([0-5][0-9]))$/)) {
            isStartTime = true
          }
        } else if (!value[1]) {
          isStartTime = true
        }
        return {
          valid: isStartTime
        }
      },
      message: this.$t('message.is_start_time')
    })
    extend('isEndTime', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        var isEndTime = false
        // 終了時間hh:mm形式チェック（23:59まで）
        if (value[1]) {
          if (value[1].match(/^(([0-9]|[0-1][0-9]|2[0-3]):([0-5][0-9]))$/)) {
            isEndTime = true
          }
        } else if (!value[0] && !value[2]) {
          isEndTime = true
        }
        return {
          valid: isEndTime
        }
      },
      message: this.$t('message.is_end_time')
    })
    extend('isStartEndTime', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        var isStartEndTime = true
        if (value[0] && value[1]) {
          // 大小チェック
          const start = ('0' + value[0]).slice(-5).split(':')
          const startTime = parseInt(start[0], 10) * 60 + parseInt(start[1], 10)
          const end = ('0' + value[1]).slice(-5).split(':')
          const tempEndTime = parseInt(end[0], 10) * 60 + parseInt(end[1], 10)
          const endTime = value[2] ? tempEndTime + 24 * 60 : tempEndTime
          if (startTime >= endTime) {
            isStartEndTime = false
          }
        }
        return {
          valid: isStartEndTime
        }
      },
      message: this.$t('message.is_start_end_time')
    })
    extend('isStartEndTimeWorkSchedule', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        var isStartEndTimeWorkSchedule = true
        if (!value[3] && !value[5] && value[4]) { // 3:所定休日、5:法定休日、4:勤務体系
          if (!value[0] || !value[1]) { // 0:始業時間、1:終業時間
            isStartEndTimeWorkSchedule = false
          }
        }
        return {
          valid: isStartEndTimeWorkSchedule
        }
      },
      message: this.$t('message.is_start_end_time_work_schedule')
    })
    extend('isWorkScheduleStartEndTime', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        console.log(value)
        var isWorkScheduleStartEndTime = true
        if (value[0] || value[1]) { // 0:始業時間、1:終業時間
          if (!value[4]) { // 4:勤務体系
            isWorkScheduleStartEndTime = false
          }
        }
        return {
          valid: isWorkScheduleStartEndTime
        }
      },
      message: this.$t('message.is_work_schedule_start_end_time')
    })
    extend('isFlexStartTime', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        var isStartTime = false
        // 開始時間hh:mm形式チェック（23:59まで）
        if (value[0]) {
          if (value[0].match(/^(([0-9]|[0-1][0-9]|2[0-3]):([0-5][0-9]))$/)) {
            isStartTime = true
          }
        } else if (!value[1]) {
          isStartTime = true
        }

        return {
          valid: isStartTime
        }
      },
      message: this.$t('message.is_start_time')
    })
    extend('isFlexEndTime', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        var isEndTime = false
        // 終了時間hh:mm形式チェック（23:59まで）
        if (value[1]) {
          if (value[1].match(/^(([0-9]|[0-1][0-9]|2[0-3]):([0-5][0-9]))$/)) {
            isEndTime = true
          }
        } else if (!value[0]) {
          isEndTime = true
        }

        return {
          valid: isEndTime
        }
      },
      message: this.$t('message.is_end_time')
    })
    extend('closingDateRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = false
        if (value[0].left_startmonth && value[0].left_endmonth &&
            value[1].left_startmonth && value[1].left_endmonth &&
            value[2].left_startmonth && value[2].left_endmonth &&
            value[3].left_startmonth && value[3].left_endmonth &&
            value[4].left_startmonth && value[4].left_endmonth &&
            value[5].left_startmonth && value[5].left_endmonth &&
            value[0].right_startmonth && value[0].right_endmonth &&
            value[1].right_startmonth && value[1].right_endmonth &&
            value[2].right_startmonth && value[2].right_endmonth &&
            value[3].right_startmonth && value[3].right_endmonth &&
            value[4].right_startmonth && value[4].right_endmonth &&
            value[5].right_startmonth && value[5].right_endmonth) {
          isValid = true
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.closing_date_required')
    })
    extend('closingDateContinuous', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = false
        if ((value[1].left_startmonth - value[0].left_endmonth === 86400000) &&
            (value[2].left_startmonth - value[1].left_endmonth === 86400000) &&
            (value[3].left_startmonth - value[2].left_endmonth === 86400000) &&
            (value[4].left_startmonth - value[3].left_endmonth === 86400000) &&
            (value[5].left_startmonth - value[4].left_endmonth === 86400000) &&
            (value[0].right_startmonth - value[5].left_endmonth === 86400000) &&
            (value[1].right_startmonth - value[0].right_endmonth === 86400000) &&
            (value[2].right_startmonth - value[1].right_endmonth === 86400000) &&
            (value[3].right_startmonth - value[2].right_endmonth === 86400000) &&
            (value[4].right_startmonth - value[3].right_endmonth === 86400000) &&
            (value[5].right_startmonth - value[4].right_endmonth === 86400000)) {
          isValid = true
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.closing_date_continuous')
    })
    extend('salaryClosingDateRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = false
        if (value[0].left_salary_month &&
            value[1].left_salary_month &&
            value[2].left_salary_month &&
            value[3].left_salary_month &&
            value[4].left_salary_month &&
            value[5].left_salary_month &&
            value[0].right_salary_month &&
            value[1].right_salary_month &&
            value[2].right_salary_month &&
            value[3].right_salary_month &&
            value[4].right_salary_month &&
            value[5].right_salary_month) {
          isValid = true
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.closing_date_required')
    })
    extend('isPaidHolidayPaymentRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        for (const row of value) {
          if (row.selected) continue
          if (!row.months_of_service || row.months_of_service.length === 0) {
            isValid = false
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_paid_holiday_payment_required')
    })
    extend('isPaidHolidayPaymentNumeric', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        for (const row of value) {
          if (row.selected) continue
          if (row.months_of_service) {
            if (isNaN(row.months_of_service)) {
              isValid = false
              break
            }
          }
          if (row.working_days_5) {
            if (isNaN(row.working_days_5)) {
              isValid = false
              break
            }
          }
          if (row.working_days_4) {
            if (isNaN(row.working_days_4)) {
              isValid = false
              break
            }
          }
          if (row.working_days_3) {
            if (isNaN(row.working_days_3)) {
              isValid = false
              break
            }
          }
          if (row.working_days_2) {
            if (isNaN(row.working_days_2)) {
              isValid = false
              break
            }
          }
          if (row.working_days_1) {
            if (isNaN(row.working_days_1)) {
              isValid = false
              break
            }
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_paid_holiday_payment_numeric')
    })
    extend('isPaidHolidayPaymentRequiredWorkingDays', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        for (const row of value) {
          if (row.selected) continue
          if (!row.working_days_5 &&
              !row.working_days_4 &&
              !row.working_days_3 &&
              !row.working_days_2 &&
              !row.working_days_1
          ) {
            isValid = false
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_paid_holiday_payment_required_working_days')
    })
    extend('isPaidHolidayPaymentUnique', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        const map = new Map()
        for (const row of value) {
          if (map.get(String(row.months_of_service))) {
            isValid = false
            break
          } else {
            map.set(String(row.months_of_service), row.months_of_service)
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_paid_holiday_payment_unique')
    })
    extend('isPaidHolidayManagementRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        for (const row of value) {
          if (row.selected) continue
          if ((!row.reference_month || row.reference_month.length === 0) ||
          (!row.number_of_working_days_per_week || row.number_of_working_days_per_week.length === 0) ||
          (!row.term_from || row.term_from.length === 0) ||
          (!row.term_to || row.term_to.length === 0) ||
          ((row.grant_days !== 0 && !row.grant_days) || row.grant_days.length === 0)) {
            isValid = false
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_paid_holiday_management_required')
    })
    extend('isPaidHolidayManagementNumeric', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        for (const row of value) {
          if (row.selected) continue
          if (isNaN(row.number_of_working_days_per_week) || isNaN(row.grant_days)) {
            isValid = false
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_paid_holiday_management_numeric')
    })
    extend('isSetAllDays', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        const workingDaysPerWeekMap = new Map()
        for (const row of value) {
          if (row.selected) continue

          let workingDaysPerWeekList = []
          const numberOfWorkingDaysPerWeek = parseInt(row.number_of_working_days_per_week, 10)
          if (Array.from(workingDaysPerWeekMap.keys()).includes(numberOfWorkingDaysPerWeek)) {
            workingDaysPerWeekList = workingDaysPerWeekMap.get(numberOfWorkingDaysPerWeek)
          }
          workingDaysPerWeekList.push(row)
          workingDaysPerWeekMap.set(numberOfWorkingDaysPerWeek, workingDaysPerWeekList)
        }

        for (const value of workingDaysPerWeekMap.values()) {
          let allDays = []
          for (var i = 0; i < 365; i++) {
            allDays[i] = 0
          }
          for (const row of value) {
            if (row.selected) continue

            var currentDate = new Date(2022, row.term_from_month - 1, row.term_from_day)
            var maxDate = new Date(2022, row.term_to_month - 1, row.term_to_day)
            if (currentDate > maxDate) {
              maxDate = new Date(2023, row.term_to_month - 1, row.term_to_day)
            }
            while (currentDate <= maxDate) {
              let baseDate = new Date(currentDate.getFullYear(), 0, 1)
              let days = (currentDate - baseDate) / (1000 * 60 * 60 * 24)
              if (allDays[days] === 1 && days !== 59) { // 2/29はエラーにしない
                return {
                  valid: false
                }
              }
              allDays[days] = 1
              currentDate.setDate(currentDate.getDate() + 1)
              currentDate = new Date(currentDate)
            }
          }
          if (allDays.includes(0)) {
            return {
              valid: false
            }
          }
        }
        return {
          valid: true
        }
      },
      message: this.$t('message.is_set_all_days')
    })
    extend('isRoleOrApproverRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        for (const row of value) {
          if (row.selected) continue
          if ((!row.approverl_role_code || row.approverl_role_code.length === 0) &&
            (!row.approverl_employee_code || row.approverl_employee_code.length === 0)) {
            isValid = false
            break
          }
          if ((row.approverl_employee_code && row.approverl_employee_code.length > 0) &&
            (!row.approverl_group_code || row.approverl_group_code.length === 0)) {
            isValid = false
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_role_or_approver_required')
    })
    extend('limitLegalOneWeeksMinutesRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        if (!value.limit_legal_one_weeks_minutes || value.limit_legal_one_weeks_minutes.length === 0) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.limit_legal_one_weeks_minutes_required')
    })
    extend('transformationTermFromRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        if (!value.term_from || value.term_from.length === 0) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.transformation_term_from_required')
    })
    extend('transformationMonthlyRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        if (!value.starting_date || value.starting_date.length === 0 ||
          !value.term_from || value.term_from.length === 0 ||
          !value.starting_date || value.starting_date.length === 0 ||
          !value.longest_day_hours || value.longest_day_hours.length === 0 ||
          !value.longest_day_minutes || value.longest_day_minutes.length === 0 ||
          !value.longest_week_hours || value.longest_week_hours.length === 0 ||
          !value.longest_week_minutes || value.longest_week_minutes.length === 0) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.transformation_monthly_required')
    })
    extend('transformationYearlyRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        if (!value.starting_date || value.starting_date.length === 0 ||
          !value.average_working_hours || value.average_working_hours.length === 0 ||
          !value.average_working_minutes || value.average_working_minutes.length === 0 ||
          !value.term_from || value.term_from.length === 0 ||
          !value.longest_working_day_working_hours || value.longest_working_day_working_hours.length === 0 ||
          !value.longest_working_day_working_minutes || value.longest_working_day_working_minutes.length === 0 ||
          !value.longest_working_week_working_hours || value.longest_working_week_working_hours.length === 0 ||
          !value.longest_working_week_working_minutes || value.longest_working_week_working_minutes.length === 0 ||
          !value.total_working_days || value.total_working_days.length === 0 ||
          !value.maximum_consecutive_weeks_with_more_than_48_hours_worked || value.maximum_consecutive_weeks_with_more_than_48_hours_worked.length === 0 ||
          !value.target_number_of_consecutive_working_days || value.target_number_of_consecutive_working_days.length === 0 ||
          !value.weeks_with_more_than_48_hours_worked || value.weeks_with_more_than_48_hours_worked.length === 0 ||
          !value.special_number_of_consecutive_working_days || value.special_number_of_consecutive_working_days.length === 0) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.transformation_yearly_required')
    })
    extend('isAuthorityRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        for (const row of value) {
          if (row.selected) continue
          if (!row.function || row.function.length === 0) {
            isValid = false
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_authority_required')
    })
    extend('isWorkingItemRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = false
        for (const row of value) {
          if (row.enabled) {
            isValid = true
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_working_item_required')
    })
    extend('isApprovalRouteRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = false
        for (const row of value) {
          if (!row.selected) {
            isValid = true
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_approval_route_required')
    })
    extend('isExaminationRequired', {
      params: ['target'],
      validate: (value, { target }) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = false
        if (target === '1') {
          for (const row of value) {
            if (row.selected) continue
            if (row.function === '1') {
              isValid = true
              break
            }
          }
        } else {
          isValid = true
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_examination_required')
    })
    extend('isNotDuplicatedApprover', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        const map = new Map()
        for (const row of value) {
          if (row.selected) continue
          if (!row.approverl_employee_code) continue
          if (map.get(row.approverl_group_code + row.approverl_employee_code)) {
            isValid = false
            break
          } else {
            map.set(row.approverl_group_code + row.approverl_employee_code, row.approverl_group_code + row.approverl_employee_code)
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_duplicate_approver_selected')
    })
    extend('isConfirmLast', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        let isConfirm = false
        for (const row of value) {
          // 削除行は無視
          if (row.selected) continue
          // 確認だったらフラグを立てる
          if (row.function === '3') {
            isConfirm = true
          } else {
            // 確認の後に確認以外が選択されていたらエラー
            if (isConfirm) {
              isValid = false
              break
            }
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_confirm_last')
    })
    extend('isIntegratedGroupRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let groupCount = 0
        for (const row of value) {
          if (row.value && row.value.length > 0) {
            groupCount++
          }
        }
        return {
          valid: groupCount >= 2
        }
      },
      message: this.$t('message.is_integrated_group_required')
    })
    extend('isDifferentIntegratedGroupSelected', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        const map = new Map()
        for (const row of value) {
          if (row.value && row.value.length > 0) {
            if (map.get(row.value)) {
              isValid = false
              break
            } else {
              map.set(row.value, row.name)
            }
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_different_integrated_group_selected')
    })
    extend('isDivisionGroupRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        return {
          valid: value.tableData && value.tableData.length >= 2
        }
      },
      message: this.$t('message.is_division_group_required')
    })
    extend('namesRequired', {
      ...required,
      message: this.$t('message.names_required')
    })
    extend('nameRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        for (const row of value) {
          if (!row.name) {
            isValid = false
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.name_required')
    })
    extend('groupRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        for (const row of value) {
          if (row.selected) continue
          if (!row.group_code) {
            isValid = false
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_authority_required')
    })
    extend('routeRequired', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        for (const row of value) {
          if (row.selected) continue
          if (!row.individual_route_code && !row.common_route_code) {
            isValid = false
            break
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_individual_or_common_route_required')
    })
    extend('isUniqueGroupCode', {
      validate: (value) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        let isBlankExist = false
        const map = new Map()
        for (const row of value) {
          if (row.selected) continue
          if (!row.group_code) {
            if (isBlankExist) {
              isValid = false
              break
            } else {
              isBlankExist = true
            }
          }
          if (map.get(row.group_code)) {
            isValid = false
            break
          } else {
            map.set(row.group_code, row.group_code)
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_duplicate_group_code_selected')
    })
    extend('isIndividualRouteRequired', {
      params: ['target'],
      validate: (value, { target }) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        if (target === '2') {
          for (const row of value) {
            if (row.selected) continue
            if (!row.individual_route_code) {
              isValid = false
              break
            }
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_individual_route_required')
    })
    extend('isCommonRouteRequired', {
      params: ['target'],
      validate: (value, { target }) => {
        if (value === null || value === undefined) {
          return {
            valid: false
          }
        }
        let isValid = true
        if (target === '1') {
          for (const row of value) {
            if (row.selected) continue
            if (!row.common_route_code) {
              isValid = false
              break
            }
          }
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_common_route_required')
    })
    extend('notSame', {
      params: ['target'],
      validate: (value, { target }) => {
        if (value === null || value === undefined || target === null || target === undefined) {
          return {
            valid: true
          }
        }
        return {
          valid: target !== value
        }
      },
      message: this.$t('message.no_change_password')
    })
    extend('notSameMailAddress', {
      params: ['target'],
      validate: (value, { target }) => {
        if (value === null || value === undefined || target === null || target === undefined) {
          return {
            valid: true
          }
        }
        return {
          valid: target !== value
        }
      },
      message: this.$t('message.no_change_mail_address')
    })
    extend('isUnusable', {
      validate: (value) => {
        let isValid = true
        if (!value.match(/^[a-zA-Z0-9_@!#-\s]+$/)) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_unusable_string_password')
    })
    extend('isEmployeeCodeSpace', {
      validate: (value) => {
        let isValid = true
        let trimValue = value.trim()
        if (trimValue.length !== value.length) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.employee_code_is_space')
    })
    extend('isAlphaAndNum', {
      validate: (value) => {
        // 正規表現でアルファベットor数字か確認する
        let isValid = true
        if (!value.match(/^[A-Za-z0-9]*$/)) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      // 従業員番号に英数字以外が入力されています。
      message: this.$t('message.employee_code_is_alpha_and_num')
    })
    extend('isPaidEnd', {
      validate: (value) => {
        let isValid = true
        let date = new Date()
        let year = String(date.getFullYear())
        let month = date.getMonth() + 1
        if (month <= 9) {
          month = '0' + String(month)
        }
        let day = date.getDate()
        if (day <= 9) {
          day = '0' + String(day)
        }
        let today = Number(String(year) + month + day)

        if (typeof value === 'object') {
          value = this.formatDate(value)
        }
        if (value === null || value === '') {
          return false
        }
        value = value.split('/')
        value = value.join('')
        if (Number(value) < today) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.paid_reference_date_error')
    })
    extend('isPasswordWeakly', {
      validate: (value) => {
        let isValid = true
        let zxcvbn = require('zxcvbn')
        let password = zxcvbn(value)
        if (password.score < 3) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_weakly_password')
    })
    extend('mailaddress', {
      validate: (value) => {
        let isValid = true
        var reg = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/
        if (!reg.test(value)) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.password_pattern')
    })
    extend('remarkMax9Rows', {
      validate: (value) => {
        let isValid = true
        let splitResult = value.split('\n')
        let rowCount = splitResult.length
        if (rowCount > 9) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.remark_max_9rows')
    })
    extend('basicPensionNumber', {
      validate: (value) => {
        let isValid = true
        if (value !== undefined && value !== null && value !== '') {
          let pattern = /^\d{4}-\d{6}$/
          isValid = pattern.test(value)
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.basic_pension_number_input_error')
    })
    extend('paidHolidayReferenceCheck', {
      validate: (value, { paidCheck }) => {
        return paidCheck === 'true'
      },
      params: ['paidCheck'],
      message: this.$t('message.paidHolidayReferenceCheck')
    })
    // カタカナチェック
    extend('isKatakana', {
      validate: (value) => {
        let isValid = true
        if (!value.match(/^[ァ-ヶー０-９ｦ-ﾟ0-9\s]*$/)) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_katakana')
    })
    // 半角英数字チェック
    extend('isHalfWidthAlphanumeric', {
      validate: (value) => {
        let isValid = true
        if (!value.match(/^[a-zA-Z0-9 ]*$/)) {
          isValid = false
        }
        return {
          valid: isValid
        }
      },
      message: this.$t('message.is_half_width_alphanumeric')
    })
    localize('ja', ja)
  }
}
