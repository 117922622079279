<template>
  <div>
    <form @submit.prevent="" method="GET" action="/#/user/menu" id="creditCard" ref="creditCard">
      <template v-if="isCompleted">
        <p>{{ '更新が完了しました。もし古いカード番号が表示されている場合はページを再読み込みしてみてください。' }}</p>
        <br>
      </template>
      <div><b>{{ '現在のクレジットカード番号' }}</b></div>
      <div>
        {{ !card ? '' : `${card.brand} ・・・・${card.last4}` }}
      </div>
      <div>
        {{ !card ? '' : `${card.exp_year}年${card.exp_month}月に有効期限切れ` }}
      </div>
      <br/>
      <button type="button" class="btn btn-sm btn-default btn-submit" @click="updateCreditCard">
        {{'クレジットカード番号を更新する'}}
      </button>
    </form>
  </div>
</template>
<script>
  import {loadStripe} from '@stripe/stripe-js'
  import mixin from '@/lib/mixin'

  export default {
    mixins: [mixin],
    data () {
      return {
        labels: {},
        card: undefined
      }
    },
    computed: {
      isCompleted () {
        return window.location.href.indexOf('?') > -1
      }
    },
    methods: {
      getCreditCard () {
        const params = {
          customerId: localStorage.getItem('customerId')
        }
        this.$axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeGetCreditCard', params)
        .then(res => {
          console.log(res)
          if (res.data.payments && res.data.payments.length > 0) {
            this.card = res.data.payments[0].card
          }
        }).catch(() => {})
      },
      async updateCreditCard () {
        console.log(process.env.VUE_APP_STRIPE_DATA_KEY)
        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_DATA_KEY)
        console.log(stripe)
        const postData = {
          customerId: localStorage.getItem('customerId'),
          successUrl: `${process.env.VUE_APP_ORIGIN}/#/user/credit_card?session_id={CHECKOUT_SESSION_ID}`,
          cancelUrl: `${process.env.VUE_APP_ORIGIN}/#/user/credit_card`
        }
        this.$axios.post(process.env.VUE_APP_CLOUD_FUNCTIONS_ORIGIN_FOR_STRIPE + '/stripeCreateCheckoutSessionForCreditCardUpdate', postData)
        .then(res => {
          console.log(res)
          if (res.data.checkoutSessionId) {
            stripe.redirectToCheckout({
              sessionId: res.data.checkoutSessionId
            })
          } else {
            console.log(res)
          }
        }).catch(e => {
          console.log(e)
        })
      }
    },
    created () {
      const request = {
        mail_address: localStorage.getItem('migrationMailAddress'),
        api_area: {}
      }
      this.sendDirect(`${process.env.VUE_APP_PRIVATE_API_ORIGIN}/init/change_password`, request)
      .then(res => {
        this.labels = this.getLabels(res.common_area)
      })
    },
    mounted () {
      // const self = this
      // const element = document.createElement('script')
      // element.setAttribute('src', 'https://checkout.stripe.com/checkout.js')
      // element.setAttribute('class', 'stripe-button')
      // element.setAttribute('data-key', process.env.VUE_APP_STRIPE_DATA_KEY)
      // element.setAttribute('data-email', localStorage.getItem('migrationMailAddress'))
      // element.setAttribute('data-image', '/static/img/LOGO_A1.png')
      // element.setAttribute('data-name', 'Artemis')
      // element.setAttribute('data-panel-label', 'クレジットカード番号更新')
      // element.setAttribute('data-label', 'クレジットカード番号更新')
      // element.setAttribute('data-allow-remember-me', false)
      // element.setAttribute('data-locale', 'auto')
      // element.onload = function () {
      //   document.getElementsByClassName('stripe-button-el')[0].style.display = 'none'
      //   self.getCreditCard()
      // }
      // document.getElementById('creditCard').appendChild(element)
      this.getCreditCard()
    }
  }

</script>
<style scoped>
</style>
